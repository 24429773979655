.header {
	background-color: #f2f2f2;
    // background-color: #ed9b51;
	border-bottom: 1px solid #fafafa;
}

.sticky-wrapper.animated .header {
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16);
}

.logo a { padding: 25px 0 20px 0; }
.animated .logo a { padding: 15px 0 10px 0; }

.flag-icon {
	transition: opacity .5s; 
	opacity: .6;

	&:hover { opacity: 1; }
}