.dot-after {
	@include dot-after;
}

.open-text {
	font-size: 22px;
	text-transform: none;
	color: #999;
}

.open-title {
	@include dot-after;
	@include font-heading;
}

.blog-title {
	@include font-heading;

	a {
		color: inherit;
	}
}

.read-more {
	color: $link-color;
	margin-left: 10px;

	&:hover {
		color: $link-color;
		text-decoration: underline;
	}

	.fa {
		margin-left: 5px;
	}
}

.widget {
	.widgettitle {
		@include font-heading;

		a {
			color: inherit;
			text-decoration: none;
		}
	}

	ul {
		list-style-type: square;

		li {
			color: $highlight;

			&.active {
				text-decoration: underline;
			}
		}
	}
}

.slider-caption {
	position: absolute;
	bottom: 23%;
	padding: 0;
}

.highlight-wrapping {
	position: relative;
	left: 5px;
	padding-left: 0;
	box-shadow: 5px 0 0 $highlight, -5px 0 0 $highlight;
}

.dark-opacity-bg {
	background-color: rgba($color: #000000, $alpha: 0.7);
	padding: 25px;
	color: #999999;
}

.text-white {
	color: #fff;
}

.w-50 {
	width: 50%;
}

.para-text {
	letter-spacing: normal;
}

.slider-link,
.tagcloud a {
	text-decoration: none !important;
}

.tab-content {
	> .tab-pane {
		display: none;
	}
	> .active {
		display: block;
	}
}

.fade {
	opacity: 0;
	@include transition($transition-fade);
	&.in {
		opacity: 1;
	}
}

.h-100 {
	height: 100% !important;
}
