.portfolio-body {
	.port-item {
		visibility: hidden;
		padding: 1px;

		a { display: block; overflow: hidden; text-decoration: none; }

		&:hover {
			.port-overlay {
				//background-color: rgba(0, 0, 0, 0.55);
			}

			.port-over-title {
			 	// transform: rotate(1080deg);
				//opacity: 0;
				font-size: 23px;
			}

			.port-image {
				transform: scale(1.07, 1.07);
			}
		}
	}

	.port-image {
		position: relative;
		display: block;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		padding-bottom: 64%;
		transition: 1s all;

		img {
			position: absolute;
			visibility: hidden;
		}
	}

	.port-bottom-text {
		text-transform: none;
		font-size: 16px;
		font-weight: normal;
		font-family: unset;
		height: 59px;
		padding: 0 20px;
		display: flex;
		align-items: center;
	}

	.port-overlay {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		background-color: rgba(0, 0, 0, 0.2);
		text-align: center;
		padding: 25px;
		transition: 1s background-color;
	}

	.port-over-title {
		@include font-heading;
		line-height: 1.3;
		font-size: 20px;
		text-transform: uppercase;
		z-index: 100;
		color: rgba(255, 255, 255, 0.92);
		transition: 0.2s all;
	}
}

.portfolio-detail {

	.portfolio-main-image {
		position: relative;

		&:after {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background: url('../img/port_opacity.png') repeat-x center bottom;
		}

		.slide {
			height: 565px;
			
			&:after {
				content: '';
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				background-color: rgba(0, 0, 0, 0.1);
			}
		}
	}

	.port-detail p {
		margin-bottom: 12px;
	}

	.port-slider {
		display: none;
		
		.owl-wrapper {
			display: flex !important;	
			align-items: center;
		}
	}

	.owl-controls {
		.owl-prev,
		.owl-next {
			font-size: 80px;
			position: absolute;
			top: 50%;
			margin-top: -40px;
			color: #b9b9b9;
			transform: scaleY(1.1);

			&:hover {
				color: $link-color;
			}
		}
	} 

	.owl-prev {
		left: -50px;
	}

	.owl-next {
		right: -50px;
	}
}

@include media-breakpoint-down(md) {
	.portfolio-detail .owl-controls .owl-prev, 
	.portfolio-detail .owl-controls .owl-next {
		display: none;
	}
}

.port-filter a {
	text-decoration: none !important;
}