input {
	width: auto;
}

input[type=file] {
	padding: 0;
	border: 0;
}

.form {
	table { width: 100%; }

	.EditingFormTable {
		&,
		tbody,
		tr,
		td {
			display: block;
		}
	}

	tr {
		margin-bottom: 25px;
	}

	textarea {
		height: 180px;
		max-height: 300px;
	}

	label,
	.EditingFormLabel {
		display: inline-block;
		margin-bottom: 5px;
	}

	.EditingFormLabel:not(.custom-file-label),
	.InputLabel {
		font-family: $font-family-heading;
		text-transform: uppercase;
	}

	.custom-file-label {
		font-family: $font-family-base;
	}

	.radio-list-vertical,
	.checkbox-list-vertical,
	.radio-list-horizontal {
		display: block;

		label { margin-bottom: 0; }

		.custom-control {
			margin-bottom: 7px;

			&:last-child { margin-bottom: 0; }
		}

		input { display: none; }
		br { display: none; }
	}

	.radio-list-horizontal {

		.custom-control { 
			display: inline-block;
			margin-right: 30px; 
		}
	}
	

	.ErrorLabel {
		margin-bottom: 30px;
	}

	.EditingFormErrorLabel {
		// margin-top: 18px;
	}

	.EditingFormControlNestedControl.Error {
		input[type=text],
		textarea { 
			@include error-input;
		}
	}

	.form-control-error {
		line-height: 1.5;
	}

	.ExplanationText {
		color: #bdbdbd;
		font-style: italic;
		margin-top: 6px;	
	}

	.WatermarkText {
		color: #bdbdbd;
	}

	.control-group-inline {
		display: flex;

		input[type=text] {
			margin-right: 10px;
			max-width: 300px;
			pointer-events: none;
		}

		.calendar-action { display: none; }
	}
}

.CaptchaTable {
	tr { margin: 0; }
}

input[type=text],
input[type=password],
input[type=email],
textarea, textarea.form-control {
	font-size: 120%;
	padding: 12px 20px;
	// width: 100%;
	background: #fff;
	// border-radius: 0;
	box-shadow: none!important;
}

.form {

	input[type=submit],
	.btn-submit {
		@include btn-main;
		margin-top: 15px;
		padding-left: 50px;
		padding-right: 50px;
		width: auto;
	}
}

@include media-breakpoint-down(md) {
	.form .radio-list-horizontal {
		.custom-control {
			display: block;
		}
	}
}