@import url("/CMSPages/GetResource.ashx?stylesheetfile=/StaticFiles/src/Template/css/prettyPhoto.css");
@import url("/CMSPages/GetResource.ashx?stylesheetfile=/StaticFiles/src/Template/css/owl.carousel.css");
@import url(../template/css/font-awesome.css);
@import url(../template/css/style.css);
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #696969;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 2.5px; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #4687bf;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #2f6089;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #696969;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-5 {
  margin: 5px !important; }

.mt-5,
.my-5 {
  margin-top: 5px !important; }

.mr-5,
.mx-5 {
  margin-right: 5px !important; }

.mb-5,
.my-5 {
  margin-bottom: 5px !important; }

.ml-5,
.mx-5 {
  margin-left: 5px !important; }

.m-10 {
  margin: 10px !important; }

.mt-10,
.my-10 {
  margin-top: 10px !important; }

.mr-10,
.mx-10 {
  margin-right: 10px !important; }

.mb-10,
.my-10 {
  margin-bottom: 10px !important; }

.ml-10,
.mx-10 {
  margin-left: 10px !important; }

.m-15 {
  margin: 15px !important; }

.mt-15,
.my-15 {
  margin-top: 15px !important; }

.mr-15,
.mx-15 {
  margin-right: 15px !important; }

.mb-15,
.my-15 {
  margin-bottom: 15px !important; }

.ml-15,
.mx-15 {
  margin-left: 15px !important; }

.m-20 {
  margin: 20px !important; }

.mt-20,
.my-20 {
  margin-top: 20px !important; }

.mr-20,
.mx-20 {
  margin-right: 20px !important; }

.mb-20,
.my-20 {
  margin-bottom: 20px !important; }

.ml-20,
.mx-20 {
  margin-left: 20px !important; }

.m-25 {
  margin: 25px !important; }

.mt-25,
.my-25 {
  margin-top: 25px !important; }

.mr-25,
.mx-25 {
  margin-right: 25px !important; }

.mb-25,
.my-25 {
  margin-bottom: 25px !important; }

.ml-25,
.mx-25 {
  margin-left: 25px !important; }

.m-30 {
  margin: 30px !important; }

.mt-30,
.my-30 {
  margin-top: 30px !important; }

.mr-30,
.mx-30 {
  margin-right: 30px !important; }

.mb-30,
.my-30 {
  margin-bottom: 30px !important; }

.ml-30,
.mx-30 {
  margin-left: 30px !important; }

.m-35 {
  margin: 35px !important; }

.mt-35,
.my-35 {
  margin-top: 35px !important; }

.mr-35,
.mx-35 {
  margin-right: 35px !important; }

.mb-35,
.my-35 {
  margin-bottom: 35px !important; }

.ml-35,
.mx-35 {
  margin-left: 35px !important; }

.m-40 {
  margin: 40px !important; }

.mt-40,
.my-40 {
  margin-top: 40px !important; }

.mr-40,
.mx-40 {
  margin-right: 40px !important; }

.mb-40,
.my-40 {
  margin-bottom: 40px !important; }

.ml-40,
.mx-40 {
  margin-left: 40px !important; }

.m-45 {
  margin: 45px !important; }

.mt-45,
.my-45 {
  margin-top: 45px !important; }

.mr-45,
.mx-45 {
  margin-right: 45px !important; }

.mb-45,
.my-45 {
  margin-bottom: 45px !important; }

.ml-45,
.mx-45 {
  margin-left: 45px !important; }

.m-50 {
  margin: 50px !important; }

.mt-50,
.my-50 {
  margin-top: 50px !important; }

.mr-50,
.mx-50 {
  margin-right: 50px !important; }

.mb-50,
.my-50 {
  margin-bottom: 50px !important; }

.ml-50,
.mx-50 {
  margin-left: 50px !important; }

.m-55 {
  margin: 55px !important; }

.mt-55,
.my-55 {
  margin-top: 55px !important; }

.mr-55,
.mx-55 {
  margin-right: 55px !important; }

.mb-55,
.my-55 {
  margin-bottom: 55px !important; }

.ml-55,
.mx-55 {
  margin-left: 55px !important; }

.m-60 {
  margin: 60px !important; }

.mt-60,
.my-60 {
  margin-top: 60px !important; }

.mr-60,
.mx-60 {
  margin-right: 60px !important; }

.mb-60,
.my-60 {
  margin-bottom: 60px !important; }

.ml-60,
.mx-60 {
  margin-left: 60px !important; }

.m-65 {
  margin: 65px !important; }

.mt-65,
.my-65 {
  margin-top: 65px !important; }

.mr-65,
.mx-65 {
  margin-right: 65px !important; }

.mb-65,
.my-65 {
  margin-bottom: 65px !important; }

.ml-65,
.mx-65 {
  margin-left: 65px !important; }

.m-70 {
  margin: 75px !important; }

.mt-70,
.my-70 {
  margin-top: 75px !important; }

.mr-70,
.mx-70 {
  margin-right: 75px !important; }

.mb-70,
.my-70 {
  margin-bottom: 75px !important; }

.ml-70,
.mx-70 {
  margin-left: 75px !important; }

.m-75 {
  margin: 80px !important; }

.mt-75,
.my-75 {
  margin-top: 80px !important; }

.mr-75,
.mx-75 {
  margin-right: 80px !important; }

.mb-75,
.my-75 {
  margin-bottom: 80px !important; }

.ml-75,
.mx-75 {
  margin-left: 80px !important; }

.m-80 {
  margin: 80px !important; }

.mt-80,
.my-80 {
  margin-top: 80px !important; }

.mr-80,
.mx-80 {
  margin-right: 80px !important; }

.mb-80,
.my-80 {
  margin-bottom: 80px !important; }

.ml-80,
.mx-80 {
  margin-left: 80px !important; }

.m-85 {
  margin: 85px !important; }

.mt-85,
.my-85 {
  margin-top: 85px !important; }

.mr-85,
.mx-85 {
  margin-right: 85px !important; }

.mb-85,
.my-85 {
  margin-bottom: 85px !important; }

.ml-85,
.mx-85 {
  margin-left: 85px !important; }

.m-90 {
  margin: 90px !important; }

.mt-90,
.my-90 {
  margin-top: 90px !important; }

.mr-90,
.mx-90 {
  margin-right: 90px !important; }

.mb-90,
.my-90 {
  margin-bottom: 90px !important; }

.ml-90,
.mx-90 {
  margin-left: 90px !important; }

.m-95 {
  margin: 95px !important; }

.mt-95,
.my-95 {
  margin-top: 95px !important; }

.mr-95,
.mx-95 {
  margin-right: 95px !important; }

.mb-95,
.my-95 {
  margin-bottom: 95px !important; }

.ml-95,
.mx-95 {
  margin-left: 95px !important; }

.m-100 {
  margin: 100px !important; }

.mt-100,
.my-100 {
  margin-top: 100px !important; }

.mr-100,
.mx-100 {
  margin-right: 100px !important; }

.mb-100,
.my-100 {
  margin-bottom: 100px !important; }

.ml-100,
.mx-100 {
  margin-left: 100px !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-5 {
  padding: 5px !important; }

.pt-5,
.py-5 {
  padding-top: 5px !important; }

.pr-5,
.px-5 {
  padding-right: 5px !important; }

.pb-5,
.py-5 {
  padding-bottom: 5px !important; }

.pl-5,
.px-5 {
  padding-left: 5px !important; }

.p-10 {
  padding: 10px !important; }

.pt-10,
.py-10 {
  padding-top: 10px !important; }

.pr-10,
.px-10 {
  padding-right: 10px !important; }

.pb-10,
.py-10 {
  padding-bottom: 10px !important; }

.pl-10,
.px-10 {
  padding-left: 10px !important; }

.p-15 {
  padding: 15px !important; }

.pt-15,
.py-15 {
  padding-top: 15px !important; }

.pr-15,
.px-15 {
  padding-right: 15px !important; }

.pb-15,
.py-15 {
  padding-bottom: 15px !important; }

.pl-15,
.px-15 {
  padding-left: 15px !important; }

.p-20 {
  padding: 20px !important; }

.pt-20,
.py-20 {
  padding-top: 20px !important; }

.pr-20,
.px-20 {
  padding-right: 20px !important; }

.pb-20,
.py-20 {
  padding-bottom: 20px !important; }

.pl-20,
.px-20 {
  padding-left: 20px !important; }

.p-25 {
  padding: 25px !important; }

.pt-25,
.py-25 {
  padding-top: 25px !important; }

.pr-25,
.px-25 {
  padding-right: 25px !important; }

.pb-25,
.py-25 {
  padding-bottom: 25px !important; }

.pl-25,
.px-25 {
  padding-left: 25px !important; }

.p-30 {
  padding: 30px !important; }

.pt-30,
.py-30 {
  padding-top: 30px !important; }

.pr-30,
.px-30 {
  padding-right: 30px !important; }

.pb-30,
.py-30 {
  padding-bottom: 30px !important; }

.pl-30,
.px-30 {
  padding-left: 30px !important; }

.p-35 {
  padding: 35px !important; }

.pt-35,
.py-35 {
  padding-top: 35px !important; }

.pr-35,
.px-35 {
  padding-right: 35px !important; }

.pb-35,
.py-35 {
  padding-bottom: 35px !important; }

.pl-35,
.px-35 {
  padding-left: 35px !important; }

.p-40 {
  padding: 40px !important; }

.pt-40,
.py-40 {
  padding-top: 40px !important; }

.pr-40,
.px-40 {
  padding-right: 40px !important; }

.pb-40,
.py-40 {
  padding-bottom: 40px !important; }

.pl-40,
.px-40 {
  padding-left: 40px !important; }

.p-45 {
  padding: 45px !important; }

.pt-45,
.py-45 {
  padding-top: 45px !important; }

.pr-45,
.px-45 {
  padding-right: 45px !important; }

.pb-45,
.py-45 {
  padding-bottom: 45px !important; }

.pl-45,
.px-45 {
  padding-left: 45px !important; }

.p-50 {
  padding: 50px !important; }

.pt-50,
.py-50 {
  padding-top: 50px !important; }

.pr-50,
.px-50 {
  padding-right: 50px !important; }

.pb-50,
.py-50 {
  padding-bottom: 50px !important; }

.pl-50,
.px-50 {
  padding-left: 50px !important; }

.p-55 {
  padding: 55px !important; }

.pt-55,
.py-55 {
  padding-top: 55px !important; }

.pr-55,
.px-55 {
  padding-right: 55px !important; }

.pb-55,
.py-55 {
  padding-bottom: 55px !important; }

.pl-55,
.px-55 {
  padding-left: 55px !important; }

.p-60 {
  padding: 60px !important; }

.pt-60,
.py-60 {
  padding-top: 60px !important; }

.pr-60,
.px-60 {
  padding-right: 60px !important; }

.pb-60,
.py-60 {
  padding-bottom: 60px !important; }

.pl-60,
.px-60 {
  padding-left: 60px !important; }

.p-65 {
  padding: 65px !important; }

.pt-65,
.py-65 {
  padding-top: 65px !important; }

.pr-65,
.px-65 {
  padding-right: 65px !important; }

.pb-65,
.py-65 {
  padding-bottom: 65px !important; }

.pl-65,
.px-65 {
  padding-left: 65px !important; }

.p-70 {
  padding: 75px !important; }

.pt-70,
.py-70 {
  padding-top: 75px !important; }

.pr-70,
.px-70 {
  padding-right: 75px !important; }

.pb-70,
.py-70 {
  padding-bottom: 75px !important; }

.pl-70,
.px-70 {
  padding-left: 75px !important; }

.p-75 {
  padding: 80px !important; }

.pt-75,
.py-75 {
  padding-top: 80px !important; }

.pr-75,
.px-75 {
  padding-right: 80px !important; }

.pb-75,
.py-75 {
  padding-bottom: 80px !important; }

.pl-75,
.px-75 {
  padding-left: 80px !important; }

.p-80 {
  padding: 80px !important; }

.pt-80,
.py-80 {
  padding-top: 80px !important; }

.pr-80,
.px-80 {
  padding-right: 80px !important; }

.pb-80,
.py-80 {
  padding-bottom: 80px !important; }

.pl-80,
.px-80 {
  padding-left: 80px !important; }

.p-85 {
  padding: 85px !important; }

.pt-85,
.py-85 {
  padding-top: 85px !important; }

.pr-85,
.px-85 {
  padding-right: 85px !important; }

.pb-85,
.py-85 {
  padding-bottom: 85px !important; }

.pl-85,
.px-85 {
  padding-left: 85px !important; }

.p-90 {
  padding: 90px !important; }

.pt-90,
.py-90 {
  padding-top: 90px !important; }

.pr-90,
.px-90 {
  padding-right: 90px !important; }

.pb-90,
.py-90 {
  padding-bottom: 90px !important; }

.pl-90,
.px-90 {
  padding-left: 90px !important; }

.p-95 {
  padding: 95px !important; }

.pt-95,
.py-95 {
  padding-top: 95px !important; }

.pr-95,
.px-95 {
  padding-right: 95px !important; }

.pb-95,
.py-95 {
  padding-bottom: 95px !important; }

.pl-95,
.px-95 {
  padding-left: 95px !important; }

.p-100 {
  padding: 100px !important; }

.pt-100,
.py-100 {
  padding-top: 100px !important; }

.pr-100,
.px-100 {
  padding-right: 100px !important; }

.pb-100,
.py-100 {
  padding-bottom: 100px !important; }

.pl-100,
.px-100 {
  padding-left: 100px !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-5 {
    margin: 5px !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 5px !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 5px !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 5px !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 5px !important; }
  .m-sm-10 {
    margin: 10px !important; }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 10px !important; }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 10px !important; }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 10px !important; }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 10px !important; }
  .m-sm-15 {
    margin: 15px !important; }
  .mt-sm-15,
  .my-sm-15 {
    margin-top: 15px !important; }
  .mr-sm-15,
  .mx-sm-15 {
    margin-right: 15px !important; }
  .mb-sm-15,
  .my-sm-15 {
    margin-bottom: 15px !important; }
  .ml-sm-15,
  .mx-sm-15 {
    margin-left: 15px !important; }
  .m-sm-20 {
    margin: 20px !important; }
  .mt-sm-20,
  .my-sm-20 {
    margin-top: 20px !important; }
  .mr-sm-20,
  .mx-sm-20 {
    margin-right: 20px !important; }
  .mb-sm-20,
  .my-sm-20 {
    margin-bottom: 20px !important; }
  .ml-sm-20,
  .mx-sm-20 {
    margin-left: 20px !important; }
  .m-sm-25 {
    margin: 25px !important; }
  .mt-sm-25,
  .my-sm-25 {
    margin-top: 25px !important; }
  .mr-sm-25,
  .mx-sm-25 {
    margin-right: 25px !important; }
  .mb-sm-25,
  .my-sm-25 {
    margin-bottom: 25px !important; }
  .ml-sm-25,
  .mx-sm-25 {
    margin-left: 25px !important; }
  .m-sm-30 {
    margin: 30px !important; }
  .mt-sm-30,
  .my-sm-30 {
    margin-top: 30px !important; }
  .mr-sm-30,
  .mx-sm-30 {
    margin-right: 30px !important; }
  .mb-sm-30,
  .my-sm-30 {
    margin-bottom: 30px !important; }
  .ml-sm-30,
  .mx-sm-30 {
    margin-left: 30px !important; }
  .m-sm-35 {
    margin: 35px !important; }
  .mt-sm-35,
  .my-sm-35 {
    margin-top: 35px !important; }
  .mr-sm-35,
  .mx-sm-35 {
    margin-right: 35px !important; }
  .mb-sm-35,
  .my-sm-35 {
    margin-bottom: 35px !important; }
  .ml-sm-35,
  .mx-sm-35 {
    margin-left: 35px !important; }
  .m-sm-40 {
    margin: 40px !important; }
  .mt-sm-40,
  .my-sm-40 {
    margin-top: 40px !important; }
  .mr-sm-40,
  .mx-sm-40 {
    margin-right: 40px !important; }
  .mb-sm-40,
  .my-sm-40 {
    margin-bottom: 40px !important; }
  .ml-sm-40,
  .mx-sm-40 {
    margin-left: 40px !important; }
  .m-sm-45 {
    margin: 45px !important; }
  .mt-sm-45,
  .my-sm-45 {
    margin-top: 45px !important; }
  .mr-sm-45,
  .mx-sm-45 {
    margin-right: 45px !important; }
  .mb-sm-45,
  .my-sm-45 {
    margin-bottom: 45px !important; }
  .ml-sm-45,
  .mx-sm-45 {
    margin-left: 45px !important; }
  .m-sm-50 {
    margin: 50px !important; }
  .mt-sm-50,
  .my-sm-50 {
    margin-top: 50px !important; }
  .mr-sm-50,
  .mx-sm-50 {
    margin-right: 50px !important; }
  .mb-sm-50,
  .my-sm-50 {
    margin-bottom: 50px !important; }
  .ml-sm-50,
  .mx-sm-50 {
    margin-left: 50px !important; }
  .m-sm-55 {
    margin: 55px !important; }
  .mt-sm-55,
  .my-sm-55 {
    margin-top: 55px !important; }
  .mr-sm-55,
  .mx-sm-55 {
    margin-right: 55px !important; }
  .mb-sm-55,
  .my-sm-55 {
    margin-bottom: 55px !important; }
  .ml-sm-55,
  .mx-sm-55 {
    margin-left: 55px !important; }
  .m-sm-60 {
    margin: 60px !important; }
  .mt-sm-60,
  .my-sm-60 {
    margin-top: 60px !important; }
  .mr-sm-60,
  .mx-sm-60 {
    margin-right: 60px !important; }
  .mb-sm-60,
  .my-sm-60 {
    margin-bottom: 60px !important; }
  .ml-sm-60,
  .mx-sm-60 {
    margin-left: 60px !important; }
  .m-sm-65 {
    margin: 65px !important; }
  .mt-sm-65,
  .my-sm-65 {
    margin-top: 65px !important; }
  .mr-sm-65,
  .mx-sm-65 {
    margin-right: 65px !important; }
  .mb-sm-65,
  .my-sm-65 {
    margin-bottom: 65px !important; }
  .ml-sm-65,
  .mx-sm-65 {
    margin-left: 65px !important; }
  .m-sm-70 {
    margin: 75px !important; }
  .mt-sm-70,
  .my-sm-70 {
    margin-top: 75px !important; }
  .mr-sm-70,
  .mx-sm-70 {
    margin-right: 75px !important; }
  .mb-sm-70,
  .my-sm-70 {
    margin-bottom: 75px !important; }
  .ml-sm-70,
  .mx-sm-70 {
    margin-left: 75px !important; }
  .m-sm-75 {
    margin: 80px !important; }
  .mt-sm-75,
  .my-sm-75 {
    margin-top: 80px !important; }
  .mr-sm-75,
  .mx-sm-75 {
    margin-right: 80px !important; }
  .mb-sm-75,
  .my-sm-75 {
    margin-bottom: 80px !important; }
  .ml-sm-75,
  .mx-sm-75 {
    margin-left: 80px !important; }
  .m-sm-80 {
    margin: 80px !important; }
  .mt-sm-80,
  .my-sm-80 {
    margin-top: 80px !important; }
  .mr-sm-80,
  .mx-sm-80 {
    margin-right: 80px !important; }
  .mb-sm-80,
  .my-sm-80 {
    margin-bottom: 80px !important; }
  .ml-sm-80,
  .mx-sm-80 {
    margin-left: 80px !important; }
  .m-sm-85 {
    margin: 85px !important; }
  .mt-sm-85,
  .my-sm-85 {
    margin-top: 85px !important; }
  .mr-sm-85,
  .mx-sm-85 {
    margin-right: 85px !important; }
  .mb-sm-85,
  .my-sm-85 {
    margin-bottom: 85px !important; }
  .ml-sm-85,
  .mx-sm-85 {
    margin-left: 85px !important; }
  .m-sm-90 {
    margin: 90px !important; }
  .mt-sm-90,
  .my-sm-90 {
    margin-top: 90px !important; }
  .mr-sm-90,
  .mx-sm-90 {
    margin-right: 90px !important; }
  .mb-sm-90,
  .my-sm-90 {
    margin-bottom: 90px !important; }
  .ml-sm-90,
  .mx-sm-90 {
    margin-left: 90px !important; }
  .m-sm-95 {
    margin: 95px !important; }
  .mt-sm-95,
  .my-sm-95 {
    margin-top: 95px !important; }
  .mr-sm-95,
  .mx-sm-95 {
    margin-right: 95px !important; }
  .mb-sm-95,
  .my-sm-95 {
    margin-bottom: 95px !important; }
  .ml-sm-95,
  .mx-sm-95 {
    margin-left: 95px !important; }
  .m-sm-100 {
    margin: 100px !important; }
  .mt-sm-100,
  .my-sm-100 {
    margin-top: 100px !important; }
  .mr-sm-100,
  .mx-sm-100 {
    margin-right: 100px !important; }
  .mb-sm-100,
  .my-sm-100 {
    margin-bottom: 100px !important; }
  .ml-sm-100,
  .mx-sm-100 {
    margin-left: 100px !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-5 {
    padding: 5px !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 5px !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 5px !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 5px !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 5px !important; }
  .p-sm-10 {
    padding: 10px !important; }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 10px !important; }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 10px !important; }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 10px !important; }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 10px !important; }
  .p-sm-15 {
    padding: 15px !important; }
  .pt-sm-15,
  .py-sm-15 {
    padding-top: 15px !important; }
  .pr-sm-15,
  .px-sm-15 {
    padding-right: 15px !important; }
  .pb-sm-15,
  .py-sm-15 {
    padding-bottom: 15px !important; }
  .pl-sm-15,
  .px-sm-15 {
    padding-left: 15px !important; }
  .p-sm-20 {
    padding: 20px !important; }
  .pt-sm-20,
  .py-sm-20 {
    padding-top: 20px !important; }
  .pr-sm-20,
  .px-sm-20 {
    padding-right: 20px !important; }
  .pb-sm-20,
  .py-sm-20 {
    padding-bottom: 20px !important; }
  .pl-sm-20,
  .px-sm-20 {
    padding-left: 20px !important; }
  .p-sm-25 {
    padding: 25px !important; }
  .pt-sm-25,
  .py-sm-25 {
    padding-top: 25px !important; }
  .pr-sm-25,
  .px-sm-25 {
    padding-right: 25px !important; }
  .pb-sm-25,
  .py-sm-25 {
    padding-bottom: 25px !important; }
  .pl-sm-25,
  .px-sm-25 {
    padding-left: 25px !important; }
  .p-sm-30 {
    padding: 30px !important; }
  .pt-sm-30,
  .py-sm-30 {
    padding-top: 30px !important; }
  .pr-sm-30,
  .px-sm-30 {
    padding-right: 30px !important; }
  .pb-sm-30,
  .py-sm-30 {
    padding-bottom: 30px !important; }
  .pl-sm-30,
  .px-sm-30 {
    padding-left: 30px !important; }
  .p-sm-35 {
    padding: 35px !important; }
  .pt-sm-35,
  .py-sm-35 {
    padding-top: 35px !important; }
  .pr-sm-35,
  .px-sm-35 {
    padding-right: 35px !important; }
  .pb-sm-35,
  .py-sm-35 {
    padding-bottom: 35px !important; }
  .pl-sm-35,
  .px-sm-35 {
    padding-left: 35px !important; }
  .p-sm-40 {
    padding: 40px !important; }
  .pt-sm-40,
  .py-sm-40 {
    padding-top: 40px !important; }
  .pr-sm-40,
  .px-sm-40 {
    padding-right: 40px !important; }
  .pb-sm-40,
  .py-sm-40 {
    padding-bottom: 40px !important; }
  .pl-sm-40,
  .px-sm-40 {
    padding-left: 40px !important; }
  .p-sm-45 {
    padding: 45px !important; }
  .pt-sm-45,
  .py-sm-45 {
    padding-top: 45px !important; }
  .pr-sm-45,
  .px-sm-45 {
    padding-right: 45px !important; }
  .pb-sm-45,
  .py-sm-45 {
    padding-bottom: 45px !important; }
  .pl-sm-45,
  .px-sm-45 {
    padding-left: 45px !important; }
  .p-sm-50 {
    padding: 50px !important; }
  .pt-sm-50,
  .py-sm-50 {
    padding-top: 50px !important; }
  .pr-sm-50,
  .px-sm-50 {
    padding-right: 50px !important; }
  .pb-sm-50,
  .py-sm-50 {
    padding-bottom: 50px !important; }
  .pl-sm-50,
  .px-sm-50 {
    padding-left: 50px !important; }
  .p-sm-55 {
    padding: 55px !important; }
  .pt-sm-55,
  .py-sm-55 {
    padding-top: 55px !important; }
  .pr-sm-55,
  .px-sm-55 {
    padding-right: 55px !important; }
  .pb-sm-55,
  .py-sm-55 {
    padding-bottom: 55px !important; }
  .pl-sm-55,
  .px-sm-55 {
    padding-left: 55px !important; }
  .p-sm-60 {
    padding: 60px !important; }
  .pt-sm-60,
  .py-sm-60 {
    padding-top: 60px !important; }
  .pr-sm-60,
  .px-sm-60 {
    padding-right: 60px !important; }
  .pb-sm-60,
  .py-sm-60 {
    padding-bottom: 60px !important; }
  .pl-sm-60,
  .px-sm-60 {
    padding-left: 60px !important; }
  .p-sm-65 {
    padding: 65px !important; }
  .pt-sm-65,
  .py-sm-65 {
    padding-top: 65px !important; }
  .pr-sm-65,
  .px-sm-65 {
    padding-right: 65px !important; }
  .pb-sm-65,
  .py-sm-65 {
    padding-bottom: 65px !important; }
  .pl-sm-65,
  .px-sm-65 {
    padding-left: 65px !important; }
  .p-sm-70 {
    padding: 75px !important; }
  .pt-sm-70,
  .py-sm-70 {
    padding-top: 75px !important; }
  .pr-sm-70,
  .px-sm-70 {
    padding-right: 75px !important; }
  .pb-sm-70,
  .py-sm-70 {
    padding-bottom: 75px !important; }
  .pl-sm-70,
  .px-sm-70 {
    padding-left: 75px !important; }
  .p-sm-75 {
    padding: 80px !important; }
  .pt-sm-75,
  .py-sm-75 {
    padding-top: 80px !important; }
  .pr-sm-75,
  .px-sm-75 {
    padding-right: 80px !important; }
  .pb-sm-75,
  .py-sm-75 {
    padding-bottom: 80px !important; }
  .pl-sm-75,
  .px-sm-75 {
    padding-left: 80px !important; }
  .p-sm-80 {
    padding: 80px !important; }
  .pt-sm-80,
  .py-sm-80 {
    padding-top: 80px !important; }
  .pr-sm-80,
  .px-sm-80 {
    padding-right: 80px !important; }
  .pb-sm-80,
  .py-sm-80 {
    padding-bottom: 80px !important; }
  .pl-sm-80,
  .px-sm-80 {
    padding-left: 80px !important; }
  .p-sm-85 {
    padding: 85px !important; }
  .pt-sm-85,
  .py-sm-85 {
    padding-top: 85px !important; }
  .pr-sm-85,
  .px-sm-85 {
    padding-right: 85px !important; }
  .pb-sm-85,
  .py-sm-85 {
    padding-bottom: 85px !important; }
  .pl-sm-85,
  .px-sm-85 {
    padding-left: 85px !important; }
  .p-sm-90 {
    padding: 90px !important; }
  .pt-sm-90,
  .py-sm-90 {
    padding-top: 90px !important; }
  .pr-sm-90,
  .px-sm-90 {
    padding-right: 90px !important; }
  .pb-sm-90,
  .py-sm-90 {
    padding-bottom: 90px !important; }
  .pl-sm-90,
  .px-sm-90 {
    padding-left: 90px !important; }
  .p-sm-95 {
    padding: 95px !important; }
  .pt-sm-95,
  .py-sm-95 {
    padding-top: 95px !important; }
  .pr-sm-95,
  .px-sm-95 {
    padding-right: 95px !important; }
  .pb-sm-95,
  .py-sm-95 {
    padding-bottom: 95px !important; }
  .pl-sm-95,
  .px-sm-95 {
    padding-left: 95px !important; }
  .p-sm-100 {
    padding: 100px !important; }
  .pt-sm-100,
  .py-sm-100 {
    padding-top: 100px !important; }
  .pr-sm-100,
  .px-sm-100 {
    padding-right: 100px !important; }
  .pb-sm-100,
  .py-sm-100 {
    padding-bottom: 100px !important; }
  .pl-sm-100,
  .px-sm-100 {
    padding-left: 100px !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-5 {
    margin: 5px !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 5px !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 5px !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 5px !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 5px !important; }
  .m-md-10 {
    margin: 10px !important; }
  .mt-md-10,
  .my-md-10 {
    margin-top: 10px !important; }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 10px !important; }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 10px !important; }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 10px !important; }
  .m-md-15 {
    margin: 15px !important; }
  .mt-md-15,
  .my-md-15 {
    margin-top: 15px !important; }
  .mr-md-15,
  .mx-md-15 {
    margin-right: 15px !important; }
  .mb-md-15,
  .my-md-15 {
    margin-bottom: 15px !important; }
  .ml-md-15,
  .mx-md-15 {
    margin-left: 15px !important; }
  .m-md-20 {
    margin: 20px !important; }
  .mt-md-20,
  .my-md-20 {
    margin-top: 20px !important; }
  .mr-md-20,
  .mx-md-20 {
    margin-right: 20px !important; }
  .mb-md-20,
  .my-md-20 {
    margin-bottom: 20px !important; }
  .ml-md-20,
  .mx-md-20 {
    margin-left: 20px !important; }
  .m-md-25 {
    margin: 25px !important; }
  .mt-md-25,
  .my-md-25 {
    margin-top: 25px !important; }
  .mr-md-25,
  .mx-md-25 {
    margin-right: 25px !important; }
  .mb-md-25,
  .my-md-25 {
    margin-bottom: 25px !important; }
  .ml-md-25,
  .mx-md-25 {
    margin-left: 25px !important; }
  .m-md-30 {
    margin: 30px !important; }
  .mt-md-30,
  .my-md-30 {
    margin-top: 30px !important; }
  .mr-md-30,
  .mx-md-30 {
    margin-right: 30px !important; }
  .mb-md-30,
  .my-md-30 {
    margin-bottom: 30px !important; }
  .ml-md-30,
  .mx-md-30 {
    margin-left: 30px !important; }
  .m-md-35 {
    margin: 35px !important; }
  .mt-md-35,
  .my-md-35 {
    margin-top: 35px !important; }
  .mr-md-35,
  .mx-md-35 {
    margin-right: 35px !important; }
  .mb-md-35,
  .my-md-35 {
    margin-bottom: 35px !important; }
  .ml-md-35,
  .mx-md-35 {
    margin-left: 35px !important; }
  .m-md-40 {
    margin: 40px !important; }
  .mt-md-40,
  .my-md-40 {
    margin-top: 40px !important; }
  .mr-md-40,
  .mx-md-40 {
    margin-right: 40px !important; }
  .mb-md-40,
  .my-md-40 {
    margin-bottom: 40px !important; }
  .ml-md-40,
  .mx-md-40 {
    margin-left: 40px !important; }
  .m-md-45 {
    margin: 45px !important; }
  .mt-md-45,
  .my-md-45 {
    margin-top: 45px !important; }
  .mr-md-45,
  .mx-md-45 {
    margin-right: 45px !important; }
  .mb-md-45,
  .my-md-45 {
    margin-bottom: 45px !important; }
  .ml-md-45,
  .mx-md-45 {
    margin-left: 45px !important; }
  .m-md-50 {
    margin: 50px !important; }
  .mt-md-50,
  .my-md-50 {
    margin-top: 50px !important; }
  .mr-md-50,
  .mx-md-50 {
    margin-right: 50px !important; }
  .mb-md-50,
  .my-md-50 {
    margin-bottom: 50px !important; }
  .ml-md-50,
  .mx-md-50 {
    margin-left: 50px !important; }
  .m-md-55 {
    margin: 55px !important; }
  .mt-md-55,
  .my-md-55 {
    margin-top: 55px !important; }
  .mr-md-55,
  .mx-md-55 {
    margin-right: 55px !important; }
  .mb-md-55,
  .my-md-55 {
    margin-bottom: 55px !important; }
  .ml-md-55,
  .mx-md-55 {
    margin-left: 55px !important; }
  .m-md-60 {
    margin: 60px !important; }
  .mt-md-60,
  .my-md-60 {
    margin-top: 60px !important; }
  .mr-md-60,
  .mx-md-60 {
    margin-right: 60px !important; }
  .mb-md-60,
  .my-md-60 {
    margin-bottom: 60px !important; }
  .ml-md-60,
  .mx-md-60 {
    margin-left: 60px !important; }
  .m-md-65 {
    margin: 65px !important; }
  .mt-md-65,
  .my-md-65 {
    margin-top: 65px !important; }
  .mr-md-65,
  .mx-md-65 {
    margin-right: 65px !important; }
  .mb-md-65,
  .my-md-65 {
    margin-bottom: 65px !important; }
  .ml-md-65,
  .mx-md-65 {
    margin-left: 65px !important; }
  .m-md-70 {
    margin: 75px !important; }
  .mt-md-70,
  .my-md-70 {
    margin-top: 75px !important; }
  .mr-md-70,
  .mx-md-70 {
    margin-right: 75px !important; }
  .mb-md-70,
  .my-md-70 {
    margin-bottom: 75px !important; }
  .ml-md-70,
  .mx-md-70 {
    margin-left: 75px !important; }
  .m-md-75 {
    margin: 80px !important; }
  .mt-md-75,
  .my-md-75 {
    margin-top: 80px !important; }
  .mr-md-75,
  .mx-md-75 {
    margin-right: 80px !important; }
  .mb-md-75,
  .my-md-75 {
    margin-bottom: 80px !important; }
  .ml-md-75,
  .mx-md-75 {
    margin-left: 80px !important; }
  .m-md-80 {
    margin: 80px !important; }
  .mt-md-80,
  .my-md-80 {
    margin-top: 80px !important; }
  .mr-md-80,
  .mx-md-80 {
    margin-right: 80px !important; }
  .mb-md-80,
  .my-md-80 {
    margin-bottom: 80px !important; }
  .ml-md-80,
  .mx-md-80 {
    margin-left: 80px !important; }
  .m-md-85 {
    margin: 85px !important; }
  .mt-md-85,
  .my-md-85 {
    margin-top: 85px !important; }
  .mr-md-85,
  .mx-md-85 {
    margin-right: 85px !important; }
  .mb-md-85,
  .my-md-85 {
    margin-bottom: 85px !important; }
  .ml-md-85,
  .mx-md-85 {
    margin-left: 85px !important; }
  .m-md-90 {
    margin: 90px !important; }
  .mt-md-90,
  .my-md-90 {
    margin-top: 90px !important; }
  .mr-md-90,
  .mx-md-90 {
    margin-right: 90px !important; }
  .mb-md-90,
  .my-md-90 {
    margin-bottom: 90px !important; }
  .ml-md-90,
  .mx-md-90 {
    margin-left: 90px !important; }
  .m-md-95 {
    margin: 95px !important; }
  .mt-md-95,
  .my-md-95 {
    margin-top: 95px !important; }
  .mr-md-95,
  .mx-md-95 {
    margin-right: 95px !important; }
  .mb-md-95,
  .my-md-95 {
    margin-bottom: 95px !important; }
  .ml-md-95,
  .mx-md-95 {
    margin-left: 95px !important; }
  .m-md-100 {
    margin: 100px !important; }
  .mt-md-100,
  .my-md-100 {
    margin-top: 100px !important; }
  .mr-md-100,
  .mx-md-100 {
    margin-right: 100px !important; }
  .mb-md-100,
  .my-md-100 {
    margin-bottom: 100px !important; }
  .ml-md-100,
  .mx-md-100 {
    margin-left: 100px !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-5 {
    padding: 5px !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 5px !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 5px !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 5px !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 5px !important; }
  .p-md-10 {
    padding: 10px !important; }
  .pt-md-10,
  .py-md-10 {
    padding-top: 10px !important; }
  .pr-md-10,
  .px-md-10 {
    padding-right: 10px !important; }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 10px !important; }
  .pl-md-10,
  .px-md-10 {
    padding-left: 10px !important; }
  .p-md-15 {
    padding: 15px !important; }
  .pt-md-15,
  .py-md-15 {
    padding-top: 15px !important; }
  .pr-md-15,
  .px-md-15 {
    padding-right: 15px !important; }
  .pb-md-15,
  .py-md-15 {
    padding-bottom: 15px !important; }
  .pl-md-15,
  .px-md-15 {
    padding-left: 15px !important; }
  .p-md-20 {
    padding: 20px !important; }
  .pt-md-20,
  .py-md-20 {
    padding-top: 20px !important; }
  .pr-md-20,
  .px-md-20 {
    padding-right: 20px !important; }
  .pb-md-20,
  .py-md-20 {
    padding-bottom: 20px !important; }
  .pl-md-20,
  .px-md-20 {
    padding-left: 20px !important; }
  .p-md-25 {
    padding: 25px !important; }
  .pt-md-25,
  .py-md-25 {
    padding-top: 25px !important; }
  .pr-md-25,
  .px-md-25 {
    padding-right: 25px !important; }
  .pb-md-25,
  .py-md-25 {
    padding-bottom: 25px !important; }
  .pl-md-25,
  .px-md-25 {
    padding-left: 25px !important; }
  .p-md-30 {
    padding: 30px !important; }
  .pt-md-30,
  .py-md-30 {
    padding-top: 30px !important; }
  .pr-md-30,
  .px-md-30 {
    padding-right: 30px !important; }
  .pb-md-30,
  .py-md-30 {
    padding-bottom: 30px !important; }
  .pl-md-30,
  .px-md-30 {
    padding-left: 30px !important; }
  .p-md-35 {
    padding: 35px !important; }
  .pt-md-35,
  .py-md-35 {
    padding-top: 35px !important; }
  .pr-md-35,
  .px-md-35 {
    padding-right: 35px !important; }
  .pb-md-35,
  .py-md-35 {
    padding-bottom: 35px !important; }
  .pl-md-35,
  .px-md-35 {
    padding-left: 35px !important; }
  .p-md-40 {
    padding: 40px !important; }
  .pt-md-40,
  .py-md-40 {
    padding-top: 40px !important; }
  .pr-md-40,
  .px-md-40 {
    padding-right: 40px !important; }
  .pb-md-40,
  .py-md-40 {
    padding-bottom: 40px !important; }
  .pl-md-40,
  .px-md-40 {
    padding-left: 40px !important; }
  .p-md-45 {
    padding: 45px !important; }
  .pt-md-45,
  .py-md-45 {
    padding-top: 45px !important; }
  .pr-md-45,
  .px-md-45 {
    padding-right: 45px !important; }
  .pb-md-45,
  .py-md-45 {
    padding-bottom: 45px !important; }
  .pl-md-45,
  .px-md-45 {
    padding-left: 45px !important; }
  .p-md-50 {
    padding: 50px !important; }
  .pt-md-50,
  .py-md-50 {
    padding-top: 50px !important; }
  .pr-md-50,
  .px-md-50 {
    padding-right: 50px !important; }
  .pb-md-50,
  .py-md-50 {
    padding-bottom: 50px !important; }
  .pl-md-50,
  .px-md-50 {
    padding-left: 50px !important; }
  .p-md-55 {
    padding: 55px !important; }
  .pt-md-55,
  .py-md-55 {
    padding-top: 55px !important; }
  .pr-md-55,
  .px-md-55 {
    padding-right: 55px !important; }
  .pb-md-55,
  .py-md-55 {
    padding-bottom: 55px !important; }
  .pl-md-55,
  .px-md-55 {
    padding-left: 55px !important; }
  .p-md-60 {
    padding: 60px !important; }
  .pt-md-60,
  .py-md-60 {
    padding-top: 60px !important; }
  .pr-md-60,
  .px-md-60 {
    padding-right: 60px !important; }
  .pb-md-60,
  .py-md-60 {
    padding-bottom: 60px !important; }
  .pl-md-60,
  .px-md-60 {
    padding-left: 60px !important; }
  .p-md-65 {
    padding: 65px !important; }
  .pt-md-65,
  .py-md-65 {
    padding-top: 65px !important; }
  .pr-md-65,
  .px-md-65 {
    padding-right: 65px !important; }
  .pb-md-65,
  .py-md-65 {
    padding-bottom: 65px !important; }
  .pl-md-65,
  .px-md-65 {
    padding-left: 65px !important; }
  .p-md-70 {
    padding: 75px !important; }
  .pt-md-70,
  .py-md-70 {
    padding-top: 75px !important; }
  .pr-md-70,
  .px-md-70 {
    padding-right: 75px !important; }
  .pb-md-70,
  .py-md-70 {
    padding-bottom: 75px !important; }
  .pl-md-70,
  .px-md-70 {
    padding-left: 75px !important; }
  .p-md-75 {
    padding: 80px !important; }
  .pt-md-75,
  .py-md-75 {
    padding-top: 80px !important; }
  .pr-md-75,
  .px-md-75 {
    padding-right: 80px !important; }
  .pb-md-75,
  .py-md-75 {
    padding-bottom: 80px !important; }
  .pl-md-75,
  .px-md-75 {
    padding-left: 80px !important; }
  .p-md-80 {
    padding: 80px !important; }
  .pt-md-80,
  .py-md-80 {
    padding-top: 80px !important; }
  .pr-md-80,
  .px-md-80 {
    padding-right: 80px !important; }
  .pb-md-80,
  .py-md-80 {
    padding-bottom: 80px !important; }
  .pl-md-80,
  .px-md-80 {
    padding-left: 80px !important; }
  .p-md-85 {
    padding: 85px !important; }
  .pt-md-85,
  .py-md-85 {
    padding-top: 85px !important; }
  .pr-md-85,
  .px-md-85 {
    padding-right: 85px !important; }
  .pb-md-85,
  .py-md-85 {
    padding-bottom: 85px !important; }
  .pl-md-85,
  .px-md-85 {
    padding-left: 85px !important; }
  .p-md-90 {
    padding: 90px !important; }
  .pt-md-90,
  .py-md-90 {
    padding-top: 90px !important; }
  .pr-md-90,
  .px-md-90 {
    padding-right: 90px !important; }
  .pb-md-90,
  .py-md-90 {
    padding-bottom: 90px !important; }
  .pl-md-90,
  .px-md-90 {
    padding-left: 90px !important; }
  .p-md-95 {
    padding: 95px !important; }
  .pt-md-95,
  .py-md-95 {
    padding-top: 95px !important; }
  .pr-md-95,
  .px-md-95 {
    padding-right: 95px !important; }
  .pb-md-95,
  .py-md-95 {
    padding-bottom: 95px !important; }
  .pl-md-95,
  .px-md-95 {
    padding-left: 95px !important; }
  .p-md-100 {
    padding: 100px !important; }
  .pt-md-100,
  .py-md-100 {
    padding-top: 100px !important; }
  .pr-md-100,
  .px-md-100 {
    padding-right: 100px !important; }
  .pb-md-100,
  .py-md-100 {
    padding-bottom: 100px !important; }
  .pl-md-100,
  .px-md-100 {
    padding-left: 100px !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-5 {
    margin: 5px !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 5px !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 5px !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 5px !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 5px !important; }
  .m-lg-10 {
    margin: 10px !important; }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 10px !important; }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 10px !important; }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 10px !important; }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 10px !important; }
  .m-lg-15 {
    margin: 15px !important; }
  .mt-lg-15,
  .my-lg-15 {
    margin-top: 15px !important; }
  .mr-lg-15,
  .mx-lg-15 {
    margin-right: 15px !important; }
  .mb-lg-15,
  .my-lg-15 {
    margin-bottom: 15px !important; }
  .ml-lg-15,
  .mx-lg-15 {
    margin-left: 15px !important; }
  .m-lg-20 {
    margin: 20px !important; }
  .mt-lg-20,
  .my-lg-20 {
    margin-top: 20px !important; }
  .mr-lg-20,
  .mx-lg-20 {
    margin-right: 20px !important; }
  .mb-lg-20,
  .my-lg-20 {
    margin-bottom: 20px !important; }
  .ml-lg-20,
  .mx-lg-20 {
    margin-left: 20px !important; }
  .m-lg-25 {
    margin: 25px !important; }
  .mt-lg-25,
  .my-lg-25 {
    margin-top: 25px !important; }
  .mr-lg-25,
  .mx-lg-25 {
    margin-right: 25px !important; }
  .mb-lg-25,
  .my-lg-25 {
    margin-bottom: 25px !important; }
  .ml-lg-25,
  .mx-lg-25 {
    margin-left: 25px !important; }
  .m-lg-30 {
    margin: 30px !important; }
  .mt-lg-30,
  .my-lg-30 {
    margin-top: 30px !important; }
  .mr-lg-30,
  .mx-lg-30 {
    margin-right: 30px !important; }
  .mb-lg-30,
  .my-lg-30 {
    margin-bottom: 30px !important; }
  .ml-lg-30,
  .mx-lg-30 {
    margin-left: 30px !important; }
  .m-lg-35 {
    margin: 35px !important; }
  .mt-lg-35,
  .my-lg-35 {
    margin-top: 35px !important; }
  .mr-lg-35,
  .mx-lg-35 {
    margin-right: 35px !important; }
  .mb-lg-35,
  .my-lg-35 {
    margin-bottom: 35px !important; }
  .ml-lg-35,
  .mx-lg-35 {
    margin-left: 35px !important; }
  .m-lg-40 {
    margin: 40px !important; }
  .mt-lg-40,
  .my-lg-40 {
    margin-top: 40px !important; }
  .mr-lg-40,
  .mx-lg-40 {
    margin-right: 40px !important; }
  .mb-lg-40,
  .my-lg-40 {
    margin-bottom: 40px !important; }
  .ml-lg-40,
  .mx-lg-40 {
    margin-left: 40px !important; }
  .m-lg-45 {
    margin: 45px !important; }
  .mt-lg-45,
  .my-lg-45 {
    margin-top: 45px !important; }
  .mr-lg-45,
  .mx-lg-45 {
    margin-right: 45px !important; }
  .mb-lg-45,
  .my-lg-45 {
    margin-bottom: 45px !important; }
  .ml-lg-45,
  .mx-lg-45 {
    margin-left: 45px !important; }
  .m-lg-50 {
    margin: 50px !important; }
  .mt-lg-50,
  .my-lg-50 {
    margin-top: 50px !important; }
  .mr-lg-50,
  .mx-lg-50 {
    margin-right: 50px !important; }
  .mb-lg-50,
  .my-lg-50 {
    margin-bottom: 50px !important; }
  .ml-lg-50,
  .mx-lg-50 {
    margin-left: 50px !important; }
  .m-lg-55 {
    margin: 55px !important; }
  .mt-lg-55,
  .my-lg-55 {
    margin-top: 55px !important; }
  .mr-lg-55,
  .mx-lg-55 {
    margin-right: 55px !important; }
  .mb-lg-55,
  .my-lg-55 {
    margin-bottom: 55px !important; }
  .ml-lg-55,
  .mx-lg-55 {
    margin-left: 55px !important; }
  .m-lg-60 {
    margin: 60px !important; }
  .mt-lg-60,
  .my-lg-60 {
    margin-top: 60px !important; }
  .mr-lg-60,
  .mx-lg-60 {
    margin-right: 60px !important; }
  .mb-lg-60,
  .my-lg-60 {
    margin-bottom: 60px !important; }
  .ml-lg-60,
  .mx-lg-60 {
    margin-left: 60px !important; }
  .m-lg-65 {
    margin: 65px !important; }
  .mt-lg-65,
  .my-lg-65 {
    margin-top: 65px !important; }
  .mr-lg-65,
  .mx-lg-65 {
    margin-right: 65px !important; }
  .mb-lg-65,
  .my-lg-65 {
    margin-bottom: 65px !important; }
  .ml-lg-65,
  .mx-lg-65 {
    margin-left: 65px !important; }
  .m-lg-70 {
    margin: 75px !important; }
  .mt-lg-70,
  .my-lg-70 {
    margin-top: 75px !important; }
  .mr-lg-70,
  .mx-lg-70 {
    margin-right: 75px !important; }
  .mb-lg-70,
  .my-lg-70 {
    margin-bottom: 75px !important; }
  .ml-lg-70,
  .mx-lg-70 {
    margin-left: 75px !important; }
  .m-lg-75 {
    margin: 80px !important; }
  .mt-lg-75,
  .my-lg-75 {
    margin-top: 80px !important; }
  .mr-lg-75,
  .mx-lg-75 {
    margin-right: 80px !important; }
  .mb-lg-75,
  .my-lg-75 {
    margin-bottom: 80px !important; }
  .ml-lg-75,
  .mx-lg-75 {
    margin-left: 80px !important; }
  .m-lg-80 {
    margin: 80px !important; }
  .mt-lg-80,
  .my-lg-80 {
    margin-top: 80px !important; }
  .mr-lg-80,
  .mx-lg-80 {
    margin-right: 80px !important; }
  .mb-lg-80,
  .my-lg-80 {
    margin-bottom: 80px !important; }
  .ml-lg-80,
  .mx-lg-80 {
    margin-left: 80px !important; }
  .m-lg-85 {
    margin: 85px !important; }
  .mt-lg-85,
  .my-lg-85 {
    margin-top: 85px !important; }
  .mr-lg-85,
  .mx-lg-85 {
    margin-right: 85px !important; }
  .mb-lg-85,
  .my-lg-85 {
    margin-bottom: 85px !important; }
  .ml-lg-85,
  .mx-lg-85 {
    margin-left: 85px !important; }
  .m-lg-90 {
    margin: 90px !important; }
  .mt-lg-90,
  .my-lg-90 {
    margin-top: 90px !important; }
  .mr-lg-90,
  .mx-lg-90 {
    margin-right: 90px !important; }
  .mb-lg-90,
  .my-lg-90 {
    margin-bottom: 90px !important; }
  .ml-lg-90,
  .mx-lg-90 {
    margin-left: 90px !important; }
  .m-lg-95 {
    margin: 95px !important; }
  .mt-lg-95,
  .my-lg-95 {
    margin-top: 95px !important; }
  .mr-lg-95,
  .mx-lg-95 {
    margin-right: 95px !important; }
  .mb-lg-95,
  .my-lg-95 {
    margin-bottom: 95px !important; }
  .ml-lg-95,
  .mx-lg-95 {
    margin-left: 95px !important; }
  .m-lg-100 {
    margin: 100px !important; }
  .mt-lg-100,
  .my-lg-100 {
    margin-top: 100px !important; }
  .mr-lg-100,
  .mx-lg-100 {
    margin-right: 100px !important; }
  .mb-lg-100,
  .my-lg-100 {
    margin-bottom: 100px !important; }
  .ml-lg-100,
  .mx-lg-100 {
    margin-left: 100px !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-5 {
    padding: 5px !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 5px !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 5px !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 5px !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 5px !important; }
  .p-lg-10 {
    padding: 10px !important; }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 10px !important; }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 10px !important; }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 10px !important; }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 10px !important; }
  .p-lg-15 {
    padding: 15px !important; }
  .pt-lg-15,
  .py-lg-15 {
    padding-top: 15px !important; }
  .pr-lg-15,
  .px-lg-15 {
    padding-right: 15px !important; }
  .pb-lg-15,
  .py-lg-15 {
    padding-bottom: 15px !important; }
  .pl-lg-15,
  .px-lg-15 {
    padding-left: 15px !important; }
  .p-lg-20 {
    padding: 20px !important; }
  .pt-lg-20,
  .py-lg-20 {
    padding-top: 20px !important; }
  .pr-lg-20,
  .px-lg-20 {
    padding-right: 20px !important; }
  .pb-lg-20,
  .py-lg-20 {
    padding-bottom: 20px !important; }
  .pl-lg-20,
  .px-lg-20 {
    padding-left: 20px !important; }
  .p-lg-25 {
    padding: 25px !important; }
  .pt-lg-25,
  .py-lg-25 {
    padding-top: 25px !important; }
  .pr-lg-25,
  .px-lg-25 {
    padding-right: 25px !important; }
  .pb-lg-25,
  .py-lg-25 {
    padding-bottom: 25px !important; }
  .pl-lg-25,
  .px-lg-25 {
    padding-left: 25px !important; }
  .p-lg-30 {
    padding: 30px !important; }
  .pt-lg-30,
  .py-lg-30 {
    padding-top: 30px !important; }
  .pr-lg-30,
  .px-lg-30 {
    padding-right: 30px !important; }
  .pb-lg-30,
  .py-lg-30 {
    padding-bottom: 30px !important; }
  .pl-lg-30,
  .px-lg-30 {
    padding-left: 30px !important; }
  .p-lg-35 {
    padding: 35px !important; }
  .pt-lg-35,
  .py-lg-35 {
    padding-top: 35px !important; }
  .pr-lg-35,
  .px-lg-35 {
    padding-right: 35px !important; }
  .pb-lg-35,
  .py-lg-35 {
    padding-bottom: 35px !important; }
  .pl-lg-35,
  .px-lg-35 {
    padding-left: 35px !important; }
  .p-lg-40 {
    padding: 40px !important; }
  .pt-lg-40,
  .py-lg-40 {
    padding-top: 40px !important; }
  .pr-lg-40,
  .px-lg-40 {
    padding-right: 40px !important; }
  .pb-lg-40,
  .py-lg-40 {
    padding-bottom: 40px !important; }
  .pl-lg-40,
  .px-lg-40 {
    padding-left: 40px !important; }
  .p-lg-45 {
    padding: 45px !important; }
  .pt-lg-45,
  .py-lg-45 {
    padding-top: 45px !important; }
  .pr-lg-45,
  .px-lg-45 {
    padding-right: 45px !important; }
  .pb-lg-45,
  .py-lg-45 {
    padding-bottom: 45px !important; }
  .pl-lg-45,
  .px-lg-45 {
    padding-left: 45px !important; }
  .p-lg-50 {
    padding: 50px !important; }
  .pt-lg-50,
  .py-lg-50 {
    padding-top: 50px !important; }
  .pr-lg-50,
  .px-lg-50 {
    padding-right: 50px !important; }
  .pb-lg-50,
  .py-lg-50 {
    padding-bottom: 50px !important; }
  .pl-lg-50,
  .px-lg-50 {
    padding-left: 50px !important; }
  .p-lg-55 {
    padding: 55px !important; }
  .pt-lg-55,
  .py-lg-55 {
    padding-top: 55px !important; }
  .pr-lg-55,
  .px-lg-55 {
    padding-right: 55px !important; }
  .pb-lg-55,
  .py-lg-55 {
    padding-bottom: 55px !important; }
  .pl-lg-55,
  .px-lg-55 {
    padding-left: 55px !important; }
  .p-lg-60 {
    padding: 60px !important; }
  .pt-lg-60,
  .py-lg-60 {
    padding-top: 60px !important; }
  .pr-lg-60,
  .px-lg-60 {
    padding-right: 60px !important; }
  .pb-lg-60,
  .py-lg-60 {
    padding-bottom: 60px !important; }
  .pl-lg-60,
  .px-lg-60 {
    padding-left: 60px !important; }
  .p-lg-65 {
    padding: 65px !important; }
  .pt-lg-65,
  .py-lg-65 {
    padding-top: 65px !important; }
  .pr-lg-65,
  .px-lg-65 {
    padding-right: 65px !important; }
  .pb-lg-65,
  .py-lg-65 {
    padding-bottom: 65px !important; }
  .pl-lg-65,
  .px-lg-65 {
    padding-left: 65px !important; }
  .p-lg-70 {
    padding: 75px !important; }
  .pt-lg-70,
  .py-lg-70 {
    padding-top: 75px !important; }
  .pr-lg-70,
  .px-lg-70 {
    padding-right: 75px !important; }
  .pb-lg-70,
  .py-lg-70 {
    padding-bottom: 75px !important; }
  .pl-lg-70,
  .px-lg-70 {
    padding-left: 75px !important; }
  .p-lg-75 {
    padding: 80px !important; }
  .pt-lg-75,
  .py-lg-75 {
    padding-top: 80px !important; }
  .pr-lg-75,
  .px-lg-75 {
    padding-right: 80px !important; }
  .pb-lg-75,
  .py-lg-75 {
    padding-bottom: 80px !important; }
  .pl-lg-75,
  .px-lg-75 {
    padding-left: 80px !important; }
  .p-lg-80 {
    padding: 80px !important; }
  .pt-lg-80,
  .py-lg-80 {
    padding-top: 80px !important; }
  .pr-lg-80,
  .px-lg-80 {
    padding-right: 80px !important; }
  .pb-lg-80,
  .py-lg-80 {
    padding-bottom: 80px !important; }
  .pl-lg-80,
  .px-lg-80 {
    padding-left: 80px !important; }
  .p-lg-85 {
    padding: 85px !important; }
  .pt-lg-85,
  .py-lg-85 {
    padding-top: 85px !important; }
  .pr-lg-85,
  .px-lg-85 {
    padding-right: 85px !important; }
  .pb-lg-85,
  .py-lg-85 {
    padding-bottom: 85px !important; }
  .pl-lg-85,
  .px-lg-85 {
    padding-left: 85px !important; }
  .p-lg-90 {
    padding: 90px !important; }
  .pt-lg-90,
  .py-lg-90 {
    padding-top: 90px !important; }
  .pr-lg-90,
  .px-lg-90 {
    padding-right: 90px !important; }
  .pb-lg-90,
  .py-lg-90 {
    padding-bottom: 90px !important; }
  .pl-lg-90,
  .px-lg-90 {
    padding-left: 90px !important; }
  .p-lg-95 {
    padding: 95px !important; }
  .pt-lg-95,
  .py-lg-95 {
    padding-top: 95px !important; }
  .pr-lg-95,
  .px-lg-95 {
    padding-right: 95px !important; }
  .pb-lg-95,
  .py-lg-95 {
    padding-bottom: 95px !important; }
  .pl-lg-95,
  .px-lg-95 {
    padding-left: 95px !important; }
  .p-lg-100 {
    padding: 100px !important; }
  .pt-lg-100,
  .py-lg-100 {
    padding-top: 100px !important; }
  .pr-lg-100,
  .px-lg-100 {
    padding-right: 100px !important; }
  .pb-lg-100,
  .py-lg-100 {
    padding-bottom: 100px !important; }
  .pl-lg-100,
  .px-lg-100 {
    padding-left: 100px !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-5 {
    margin: 5px !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 5px !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 5px !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 5px !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 5px !important; }
  .m-xl-10 {
    margin: 10px !important; }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 10px !important; }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 10px !important; }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 10px !important; }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 10px !important; }
  .m-xl-15 {
    margin: 15px !important; }
  .mt-xl-15,
  .my-xl-15 {
    margin-top: 15px !important; }
  .mr-xl-15,
  .mx-xl-15 {
    margin-right: 15px !important; }
  .mb-xl-15,
  .my-xl-15 {
    margin-bottom: 15px !important; }
  .ml-xl-15,
  .mx-xl-15 {
    margin-left: 15px !important; }
  .m-xl-20 {
    margin: 20px !important; }
  .mt-xl-20,
  .my-xl-20 {
    margin-top: 20px !important; }
  .mr-xl-20,
  .mx-xl-20 {
    margin-right: 20px !important; }
  .mb-xl-20,
  .my-xl-20 {
    margin-bottom: 20px !important; }
  .ml-xl-20,
  .mx-xl-20 {
    margin-left: 20px !important; }
  .m-xl-25 {
    margin: 25px !important; }
  .mt-xl-25,
  .my-xl-25 {
    margin-top: 25px !important; }
  .mr-xl-25,
  .mx-xl-25 {
    margin-right: 25px !important; }
  .mb-xl-25,
  .my-xl-25 {
    margin-bottom: 25px !important; }
  .ml-xl-25,
  .mx-xl-25 {
    margin-left: 25px !important; }
  .m-xl-30 {
    margin: 30px !important; }
  .mt-xl-30,
  .my-xl-30 {
    margin-top: 30px !important; }
  .mr-xl-30,
  .mx-xl-30 {
    margin-right: 30px !important; }
  .mb-xl-30,
  .my-xl-30 {
    margin-bottom: 30px !important; }
  .ml-xl-30,
  .mx-xl-30 {
    margin-left: 30px !important; }
  .m-xl-35 {
    margin: 35px !important; }
  .mt-xl-35,
  .my-xl-35 {
    margin-top: 35px !important; }
  .mr-xl-35,
  .mx-xl-35 {
    margin-right: 35px !important; }
  .mb-xl-35,
  .my-xl-35 {
    margin-bottom: 35px !important; }
  .ml-xl-35,
  .mx-xl-35 {
    margin-left: 35px !important; }
  .m-xl-40 {
    margin: 40px !important; }
  .mt-xl-40,
  .my-xl-40 {
    margin-top: 40px !important; }
  .mr-xl-40,
  .mx-xl-40 {
    margin-right: 40px !important; }
  .mb-xl-40,
  .my-xl-40 {
    margin-bottom: 40px !important; }
  .ml-xl-40,
  .mx-xl-40 {
    margin-left: 40px !important; }
  .m-xl-45 {
    margin: 45px !important; }
  .mt-xl-45,
  .my-xl-45 {
    margin-top: 45px !important; }
  .mr-xl-45,
  .mx-xl-45 {
    margin-right: 45px !important; }
  .mb-xl-45,
  .my-xl-45 {
    margin-bottom: 45px !important; }
  .ml-xl-45,
  .mx-xl-45 {
    margin-left: 45px !important; }
  .m-xl-50 {
    margin: 50px !important; }
  .mt-xl-50,
  .my-xl-50 {
    margin-top: 50px !important; }
  .mr-xl-50,
  .mx-xl-50 {
    margin-right: 50px !important; }
  .mb-xl-50,
  .my-xl-50 {
    margin-bottom: 50px !important; }
  .ml-xl-50,
  .mx-xl-50 {
    margin-left: 50px !important; }
  .m-xl-55 {
    margin: 55px !important; }
  .mt-xl-55,
  .my-xl-55 {
    margin-top: 55px !important; }
  .mr-xl-55,
  .mx-xl-55 {
    margin-right: 55px !important; }
  .mb-xl-55,
  .my-xl-55 {
    margin-bottom: 55px !important; }
  .ml-xl-55,
  .mx-xl-55 {
    margin-left: 55px !important; }
  .m-xl-60 {
    margin: 60px !important; }
  .mt-xl-60,
  .my-xl-60 {
    margin-top: 60px !important; }
  .mr-xl-60,
  .mx-xl-60 {
    margin-right: 60px !important; }
  .mb-xl-60,
  .my-xl-60 {
    margin-bottom: 60px !important; }
  .ml-xl-60,
  .mx-xl-60 {
    margin-left: 60px !important; }
  .m-xl-65 {
    margin: 65px !important; }
  .mt-xl-65,
  .my-xl-65 {
    margin-top: 65px !important; }
  .mr-xl-65,
  .mx-xl-65 {
    margin-right: 65px !important; }
  .mb-xl-65,
  .my-xl-65 {
    margin-bottom: 65px !important; }
  .ml-xl-65,
  .mx-xl-65 {
    margin-left: 65px !important; }
  .m-xl-70 {
    margin: 75px !important; }
  .mt-xl-70,
  .my-xl-70 {
    margin-top: 75px !important; }
  .mr-xl-70,
  .mx-xl-70 {
    margin-right: 75px !important; }
  .mb-xl-70,
  .my-xl-70 {
    margin-bottom: 75px !important; }
  .ml-xl-70,
  .mx-xl-70 {
    margin-left: 75px !important; }
  .m-xl-75 {
    margin: 80px !important; }
  .mt-xl-75,
  .my-xl-75 {
    margin-top: 80px !important; }
  .mr-xl-75,
  .mx-xl-75 {
    margin-right: 80px !important; }
  .mb-xl-75,
  .my-xl-75 {
    margin-bottom: 80px !important; }
  .ml-xl-75,
  .mx-xl-75 {
    margin-left: 80px !important; }
  .m-xl-80 {
    margin: 80px !important; }
  .mt-xl-80,
  .my-xl-80 {
    margin-top: 80px !important; }
  .mr-xl-80,
  .mx-xl-80 {
    margin-right: 80px !important; }
  .mb-xl-80,
  .my-xl-80 {
    margin-bottom: 80px !important; }
  .ml-xl-80,
  .mx-xl-80 {
    margin-left: 80px !important; }
  .m-xl-85 {
    margin: 85px !important; }
  .mt-xl-85,
  .my-xl-85 {
    margin-top: 85px !important; }
  .mr-xl-85,
  .mx-xl-85 {
    margin-right: 85px !important; }
  .mb-xl-85,
  .my-xl-85 {
    margin-bottom: 85px !important; }
  .ml-xl-85,
  .mx-xl-85 {
    margin-left: 85px !important; }
  .m-xl-90 {
    margin: 90px !important; }
  .mt-xl-90,
  .my-xl-90 {
    margin-top: 90px !important; }
  .mr-xl-90,
  .mx-xl-90 {
    margin-right: 90px !important; }
  .mb-xl-90,
  .my-xl-90 {
    margin-bottom: 90px !important; }
  .ml-xl-90,
  .mx-xl-90 {
    margin-left: 90px !important; }
  .m-xl-95 {
    margin: 95px !important; }
  .mt-xl-95,
  .my-xl-95 {
    margin-top: 95px !important; }
  .mr-xl-95,
  .mx-xl-95 {
    margin-right: 95px !important; }
  .mb-xl-95,
  .my-xl-95 {
    margin-bottom: 95px !important; }
  .ml-xl-95,
  .mx-xl-95 {
    margin-left: 95px !important; }
  .m-xl-100 {
    margin: 100px !important; }
  .mt-xl-100,
  .my-xl-100 {
    margin-top: 100px !important; }
  .mr-xl-100,
  .mx-xl-100 {
    margin-right: 100px !important; }
  .mb-xl-100,
  .my-xl-100 {
    margin-bottom: 100px !important; }
  .ml-xl-100,
  .mx-xl-100 {
    margin-left: 100px !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-5 {
    padding: 5px !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 5px !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 5px !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 5px !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 5px !important; }
  .p-xl-10 {
    padding: 10px !important; }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 10px !important; }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 10px !important; }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 10px !important; }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 10px !important; }
  .p-xl-15 {
    padding: 15px !important; }
  .pt-xl-15,
  .py-xl-15 {
    padding-top: 15px !important; }
  .pr-xl-15,
  .px-xl-15 {
    padding-right: 15px !important; }
  .pb-xl-15,
  .py-xl-15 {
    padding-bottom: 15px !important; }
  .pl-xl-15,
  .px-xl-15 {
    padding-left: 15px !important; }
  .p-xl-20 {
    padding: 20px !important; }
  .pt-xl-20,
  .py-xl-20 {
    padding-top: 20px !important; }
  .pr-xl-20,
  .px-xl-20 {
    padding-right: 20px !important; }
  .pb-xl-20,
  .py-xl-20 {
    padding-bottom: 20px !important; }
  .pl-xl-20,
  .px-xl-20 {
    padding-left: 20px !important; }
  .p-xl-25 {
    padding: 25px !important; }
  .pt-xl-25,
  .py-xl-25 {
    padding-top: 25px !important; }
  .pr-xl-25,
  .px-xl-25 {
    padding-right: 25px !important; }
  .pb-xl-25,
  .py-xl-25 {
    padding-bottom: 25px !important; }
  .pl-xl-25,
  .px-xl-25 {
    padding-left: 25px !important; }
  .p-xl-30 {
    padding: 30px !important; }
  .pt-xl-30,
  .py-xl-30 {
    padding-top: 30px !important; }
  .pr-xl-30,
  .px-xl-30 {
    padding-right: 30px !important; }
  .pb-xl-30,
  .py-xl-30 {
    padding-bottom: 30px !important; }
  .pl-xl-30,
  .px-xl-30 {
    padding-left: 30px !important; }
  .p-xl-35 {
    padding: 35px !important; }
  .pt-xl-35,
  .py-xl-35 {
    padding-top: 35px !important; }
  .pr-xl-35,
  .px-xl-35 {
    padding-right: 35px !important; }
  .pb-xl-35,
  .py-xl-35 {
    padding-bottom: 35px !important; }
  .pl-xl-35,
  .px-xl-35 {
    padding-left: 35px !important; }
  .p-xl-40 {
    padding: 40px !important; }
  .pt-xl-40,
  .py-xl-40 {
    padding-top: 40px !important; }
  .pr-xl-40,
  .px-xl-40 {
    padding-right: 40px !important; }
  .pb-xl-40,
  .py-xl-40 {
    padding-bottom: 40px !important; }
  .pl-xl-40,
  .px-xl-40 {
    padding-left: 40px !important; }
  .p-xl-45 {
    padding: 45px !important; }
  .pt-xl-45,
  .py-xl-45 {
    padding-top: 45px !important; }
  .pr-xl-45,
  .px-xl-45 {
    padding-right: 45px !important; }
  .pb-xl-45,
  .py-xl-45 {
    padding-bottom: 45px !important; }
  .pl-xl-45,
  .px-xl-45 {
    padding-left: 45px !important; }
  .p-xl-50 {
    padding: 50px !important; }
  .pt-xl-50,
  .py-xl-50 {
    padding-top: 50px !important; }
  .pr-xl-50,
  .px-xl-50 {
    padding-right: 50px !important; }
  .pb-xl-50,
  .py-xl-50 {
    padding-bottom: 50px !important; }
  .pl-xl-50,
  .px-xl-50 {
    padding-left: 50px !important; }
  .p-xl-55 {
    padding: 55px !important; }
  .pt-xl-55,
  .py-xl-55 {
    padding-top: 55px !important; }
  .pr-xl-55,
  .px-xl-55 {
    padding-right: 55px !important; }
  .pb-xl-55,
  .py-xl-55 {
    padding-bottom: 55px !important; }
  .pl-xl-55,
  .px-xl-55 {
    padding-left: 55px !important; }
  .p-xl-60 {
    padding: 60px !important; }
  .pt-xl-60,
  .py-xl-60 {
    padding-top: 60px !important; }
  .pr-xl-60,
  .px-xl-60 {
    padding-right: 60px !important; }
  .pb-xl-60,
  .py-xl-60 {
    padding-bottom: 60px !important; }
  .pl-xl-60,
  .px-xl-60 {
    padding-left: 60px !important; }
  .p-xl-65 {
    padding: 65px !important; }
  .pt-xl-65,
  .py-xl-65 {
    padding-top: 65px !important; }
  .pr-xl-65,
  .px-xl-65 {
    padding-right: 65px !important; }
  .pb-xl-65,
  .py-xl-65 {
    padding-bottom: 65px !important; }
  .pl-xl-65,
  .px-xl-65 {
    padding-left: 65px !important; }
  .p-xl-70 {
    padding: 75px !important; }
  .pt-xl-70,
  .py-xl-70 {
    padding-top: 75px !important; }
  .pr-xl-70,
  .px-xl-70 {
    padding-right: 75px !important; }
  .pb-xl-70,
  .py-xl-70 {
    padding-bottom: 75px !important; }
  .pl-xl-70,
  .px-xl-70 {
    padding-left: 75px !important; }
  .p-xl-75 {
    padding: 80px !important; }
  .pt-xl-75,
  .py-xl-75 {
    padding-top: 80px !important; }
  .pr-xl-75,
  .px-xl-75 {
    padding-right: 80px !important; }
  .pb-xl-75,
  .py-xl-75 {
    padding-bottom: 80px !important; }
  .pl-xl-75,
  .px-xl-75 {
    padding-left: 80px !important; }
  .p-xl-80 {
    padding: 80px !important; }
  .pt-xl-80,
  .py-xl-80 {
    padding-top: 80px !important; }
  .pr-xl-80,
  .px-xl-80 {
    padding-right: 80px !important; }
  .pb-xl-80,
  .py-xl-80 {
    padding-bottom: 80px !important; }
  .pl-xl-80,
  .px-xl-80 {
    padding-left: 80px !important; }
  .p-xl-85 {
    padding: 85px !important; }
  .pt-xl-85,
  .py-xl-85 {
    padding-top: 85px !important; }
  .pr-xl-85,
  .px-xl-85 {
    padding-right: 85px !important; }
  .pb-xl-85,
  .py-xl-85 {
    padding-bottom: 85px !important; }
  .pl-xl-85,
  .px-xl-85 {
    padding-left: 85px !important; }
  .p-xl-90 {
    padding: 90px !important; }
  .pt-xl-90,
  .py-xl-90 {
    padding-top: 90px !important; }
  .pr-xl-90,
  .px-xl-90 {
    padding-right: 90px !important; }
  .pb-xl-90,
  .py-xl-90 {
    padding-bottom: 90px !important; }
  .pl-xl-90,
  .px-xl-90 {
    padding-left: 90px !important; }
  .p-xl-95 {
    padding: 95px !important; }
  .pt-xl-95,
  .py-xl-95 {
    padding-top: 95px !important; }
  .pr-xl-95,
  .px-xl-95 {
    padding-right: 95px !important; }
  .pb-xl-95,
  .py-xl-95 {
    padding-bottom: 95px !important; }
  .pl-xl-95,
  .px-xl-95 {
    padding-left: 95px !important; }
  .p-xl-100 {
    padding: 100px !important; }
  .pt-xl-100,
  .py-xl-100 {
    padding-top: 100px !important; }
  .pr-xl-100,
  .px-xl-100 {
    padding-right: 100px !important; }
  .pb-xl-100,
  .py-xl-100 {
    padding-bottom: 100px !important; }
  .pl-xl-100,
  .px-xl-100 {
    padding-left: 100px !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.bg-primary {
  background-color: #4687bf !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #366d9c !important; }

.bg-secondary {
  background-color: #ec8d3a !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #de7315 !important; }

.bg-success, .success-message,
.SuccessLabel,
.alert-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.success-message:hover,
a.SuccessLabel:hover,
a.alert-success:hover, a.bg-success:focus, a.success-message:focus,
a.SuccessLabel:focus,
a.alert-success:focus,
button.bg-success:hover,
button.success-message:hover,
button.SuccessLabel:hover,
button.alert-success:hover,
button.bg-success:focus,
button.success-message:focus,
button.SuccessLabel:focus,
button.alert-success:focus {
  background-color: #1e7e34 !important; }

.bg-info, .info-message,
.InfoLabel {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.info-message:hover,
a.InfoLabel:hover, a.bg-info:focus, a.info-message:focus,
a.InfoLabel:focus,
button.bg-info:hover,
button.info-message:hover,
button.InfoLabel:hover,
button.bg-info:focus,
button.info-message:focus,
button.InfoLabel:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger, .error-message,
.ErrorLabel,
.alert-label {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.error-message:hover,
a.ErrorLabel:hover,
a.alert-label:hover, a.bg-danger:focus, a.error-message:focus,
a.ErrorLabel:focus,
a.alert-label:focus,
button.bg-danger:hover,
button.error-message:hover,
button.ErrorLabel:hover,
button.alert-label:hover,
button.bg-danger:focus,
button.error-message:focus,
button.ErrorLabel:focus,
button.alert-label:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-highlight {
  background-color: #ff7900 !important; }

a.bg-highlight:hover, a.bg-highlight:focus,
button.bg-highlight:hover,
button.bg-highlight:focus {
  background-color: #cc6100 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #4687bf !important; }

.border-secondary {
  border-color: #ec8d3a !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-highlight {
  border-color: #ff7900 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #4687bf !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #366d9c !important; }

.text-secondary {
  color: #ec8d3a !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #de7315 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important; }

.text-danger, .EditingFormError,
.EditingFormErrorLabel,
.form-control-error {
  color: #dc3545 !important; }

a.text-danger:hover, a.EditingFormError:hover,
a.EditingFormErrorLabel:hover,
a.form-control-error:hover, a.text-danger:focus, a.EditingFormError:focus,
a.EditingFormErrorLabel:focus,
a.form-control-error:focus {
  color: #bd2130 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important; }

.text-highlight {
  color: #ff7900 !important; }

a.text-highlight:hover, a.text-highlight:focus {
  color: #cc6100 !important; }

.text-muted {
  color: #696969 !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

/*!
 * Bootstrap Grid v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@-ms-viewport {
  width: device-width; }

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*::before,
*::after {
  box-sizing: inherit; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 2px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 50px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .btn:hover, .btn:focus {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(70, 135, 191, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }
  .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
    background-image: none; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #4687bf;
  border-color: #4687bf; }
  .btn-primary:hover {
    color: #fff;
    background-color: #3973a5;
    border-color: #366d9c; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(70, 135, 191, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #4687bf;
    border-color: #4687bf; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #366d9c;
    border-color: #336693; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(70, 135, 191, 0.5); }

.btn-secondary {
  color: #333;
  background-color: #ec8d3a;
  border-color: #ec8d3a; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #e97917;
    border-color: #de7315; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(236, 141, 58, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #333;
    background-color: #ec8d3a;
    border-color: #ec8d3a; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #de7315;
    border-color: #d26d14; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(236, 141, 58, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-warning {
  color: #333;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #333;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #333;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #333;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-light {
  color: #333;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #333;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #333;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #333;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-highlight {
  color: #fff;
  background-color: #ff7900;
  border-color: #ff7900; }
  .btn-highlight:hover {
    color: #fff;
    background-color: #d96700;
    border-color: #cc6100; }
  .btn-highlight:focus, .btn-highlight.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 121, 0, 0.5); }
  .btn-highlight.disabled, .btn-highlight:disabled {
    color: #fff;
    background-color: #ff7900;
    border-color: #ff7900; }
  .btn-highlight:not(:disabled):not(.disabled):active, .btn-highlight:not(:disabled):not(.disabled).active,
  .show > .btn-highlight.dropdown-toggle {
    color: #fff;
    background-color: #cc6100;
    border-color: #bf5b00; }
    .btn-highlight:not(:disabled):not(.disabled):active:focus, .btn-highlight:not(:disabled):not(.disabled).active:focus,
    .show > .btn-highlight.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 121, 0, 0.5); }

.btn-outline-primary {
  color: #4687bf;
  background-color: transparent;
  background-image: none;
  border-color: #4687bf; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #4687bf;
    border-color: #4687bf; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(70, 135, 191, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #4687bf;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #4687bf;
    border-color: #4687bf; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(70, 135, 191, 0.5); }

.btn-outline-secondary {
  color: #ec8d3a;
  background-color: transparent;
  background-image: none;
  border-color: #ec8d3a; }
  .btn-outline-secondary:hover {
    color: #333;
    background-color: #ec8d3a;
    border-color: #ec8d3a; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(236, 141, 58, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #ec8d3a;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #333;
    background-color: #ec8d3a;
    border-color: #ec8d3a; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(236, 141, 58, 0.5); }

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #333;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #333;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #333;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #333;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-highlight {
  color: #ff7900;
  background-color: transparent;
  background-image: none;
  border-color: #ff7900; }
  .btn-outline-highlight:hover {
    color: #fff;
    background-color: #ff7900;
    border-color: #ff7900; }
  .btn-outline-highlight:focus, .btn-outline-highlight.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 121, 0, 0.5); }
  .btn-outline-highlight.disabled, .btn-outline-highlight:disabled {
    color: #ff7900;
    background-color: transparent; }
  .btn-outline-highlight:not(:disabled):not(.disabled):active, .btn-outline-highlight:not(:disabled):not(.disabled).active,
  .show > .btn-outline-highlight.dropdown-toggle {
    color: #fff;
    background-color: #ff7900;
    border-color: #ff7900; }
    .btn-outline-highlight:not(:disabled):not(.disabled):active:focus, .btn-outline-highlight:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-highlight.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 121, 0, 0.5); }

.btn-link {
  font-weight: 400;
  color: #4687bf;
  background-color: transparent; }
  .btn-link:hover {
    color: #2f6089;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #696969; }

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.form-control {
  display: block;
  width: 100%;
  padding: 12px 20px;
  font-size: 1rem;
  line-height: 1.5;
  color: #4687bf;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #4687bf;
    background-color: #fff;
    border-color: #a5c4e0;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(70, 135, 191, 0.25); }
  .form-control::placeholder {
    color: #696969;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }

select.form-control:focus::-ms-value {
  color: #4687bf;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(12px + 1px);
  padding-bottom: calc(12px + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

select.form-control-sm:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); }

.form-control-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control-lg:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px); }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #696969; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #71dd8a; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #efa2a9; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 2.5px;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #696969; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 2.5px;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: #333; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 5px;
  margin-bottom: 5px;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 5px;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #696969; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    background-color: #4687bf; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(70, 135, 191, 0.25); }
  .custom-control-input:active ~ .custom-control-label::before {
    color: #fff;
    background-color: #cbdded; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #696969; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  margin-bottom: 0; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    user-select: none;
    background-color: #dee2e6; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #4687bf; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #4687bf; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(70, 135, 191, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(70, 135, 191, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #4687bf; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(70, 135, 191, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #4687bf;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #a5c4e0;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(165, 196, 224, 0.5); }
    .custom-select:focus::-ms-value {
      color: #4687bf;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #696969;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%; }

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-control {
    border-color: #a5c4e0;
    box-shadow: 0 0 0 0.2rem rgba(70, 135, 191, 0.25); }
    .custom-file-input:focus ~ .custom-file-control::before {
      border-color: #a5c4e0; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #4687bf;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(calc(2.25rem + 2px) - 1px * 2);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #4687bf;
    content: "Browse";
    background-color: #e9ecef;
    border-left: 1px solid #ced4da;
    border-radius: 0 0.25rem 0.25rem 0; }

@keyframes fadeInRightBig {
  0% {
    transform: translateX(-2000px); }
  100% {
    transform: translateX(0); } }

.fancybox-skin {
  padding: 6px !important;
  border-radius: 0 !important; }

.icon-calendar {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  font-size: 18px;
  color: #333; }
  .icon-calendar:before {
    content: "\f073"; }

.sr-only {
  display: none; }

.btn-icon {
  padding: 0;
  background-color: transparent;
  box-shadow: none !important; }

#ui-datepicker-div {
  background: #eaeaea; }
  #ui-datepicker-div .datetime-ui-datepicker-header {
    border: 0;
    background: unset; }
  #ui-datepicker-div select {
    height: auto;
    padding: 4px;
    display: inline-block;
    vertical-align: top;
    margin: 2px 1px;
    border: 0;
    border-radius: 0; }
  #ui-datepicker-div .datetime-ui-state-default {
    color: unset;
    border: 1px solid #cccccc;
    background: #f9f9f9;
    font-weight: normal; }
  #ui-datepicker-div .datetime-ui-state-active {
    background: #4687bf;
    color: #fff; }
  #ui-datepicker-div .action-buttons {
    display: flex;
    justify-content: space-between; }
  #ui-datepicker-div .btn {
    border-radius: 0;
    font-size: 15px; }
  #ui-datepicker-div .icon-caret-right,
  #ui-datepicker-div .icon-caret-left {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0); }
  #ui-datepicker-div .icon-caret-left:before {
    content: "\f104"; }
  #ui-datepicker-div .icon-caret-right:before {
    content: "\f105"; }
  #ui-datepicker-div .datetime-ui-datepicker-prev, #ui-datepicker-div .datetime-ui-datepicker-next {
    top: 5px;
    background: #ddd;
    border: 0; }
  #ui-datepicker-div .datetime-ui-datepicker-prev {
    left: 2px; }
  #ui-datepicker-div .datetime-ui-datepicker-next {
    right: 2px; }

.info-message,
.InfoLabel {
  display: block;
  color: #fff;
  margin: 10px 0;
  padding: 5px 10px; }

.error-message,
.ErrorLabel,
.alert-label {
  display: block;
  color: #fff;
  margin: 10px 0;
  padding: 5px 10px; }

.error-message-inline {
  color: #dc3545;
  margin-top: 5px;
  font-style: italic;
  display: block; }

.success-message,
.SuccessLabel,
.alert-success {
  display: block;
  color: #fff;
  margin: 10px 0;
  padding: 5px 10px; }

.EditingFormError,
.EditingFormInfo,
.ErrorLabel {
  margin-bottom: 10px; }

.EditingFormError,
.EditingFormErrorLabel,
.form-control-error {
  display: block;
  color: #fff;
  margin: 10px 0;
  padding: 5px 10px;
  margin: 0;
  padding: 0;
  display: inline;
  word-spacing: normal; }

.typography {
  word-wrap: break-word;
  /**
	* Headings
	*/
  /**
	* Text
	*/
  /**
	* Lists
	*/
  /**
	* Images
	*/ }
  .typography:before, .typography:after {
    content: " ";
    display: table; }
  .typography:after {
    clear: both; }
  .typography h1, .typography h2, .typography h3, .typography h4, .typography h5, .typography h6,
  .typography .h1, .typography .h2, .typography .h3, .typography .h4, .typography .h5, .typography .h6 {
    font-family: "Open Sans", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 800;
    margin: 1.5em 0 0.6em;
    text-transform: uppercase; }
    .typography h1:first-child, .typography h2:first-child, .typography h3:first-child, .typography h4:first-child, .typography h5:first-child, .typography h6:first-child,
    .typography .h1:first-child, .typography .h2:first-child, .typography .h3:first-child, .typography .h4:first-child, .typography .h5:first-child, .typography .h6:first-child {
      margin-top: 0; }
    .typography h1:after, .typography h2:after, .typography h3:after, .typography h4:after, .typography h5:after, .typography h6:after,
    .typography .h1:after, .typography .h2:after, .typography .h3:after, .typography .h4:after, .typography .h5:after, .typography .h6:after {
      content: '.';
      color: #ff7900;
      font-family: 'Raleway', sans-serif; }
    .typography h1 a, .typography h2 a, .typography h3 a, .typography h4 a, .typography h5 a, .typography h6 a,
    .typography .h1 a, .typography .h2 a, .typography .h3 a, .typography .h4 a, .typography .h5 a, .typography .h6 a {
      color: inherit;
      display: block; }
  .typography h1, .typography .h1, .typography .cms-bootstrap h1 {
    font-size: 35px; }
  .typography h2, .typography .h2, .typography .cms-bootstrap h2 {
    font-size: 24px; }
  .typography h3, .typography .h3, .typography .cms-bootstrap h3 {
    font-size: 20px; }
  .typography h4, .typography .h4, .typography .cms-bootstrap h4 {
    font-size: 16px; }
  .typography h5, .typography .h5, .typography .cms-bootstrap h5 {
    font-size: 14px; }
  .typography h6, .typography .h6, .typography .cms-bootstrap h6 {
    font-size: 12px; }
  .typography p {
    margin: 25px 0; }
    .typography p:first-child, .typography p:first-of-type {
      margin-top: 0; }
    .typography p:last-child {
      margin-bottom: 0; }
  .typography ul,
  .typography ol {
    margin: 20px 0 20px 20px;
    padding: 0; }
    .typography ul:first-child, .typography ul:last-child,
    .typography ol:first-child,
    .typography ol:last-child {
      margin-bottom: 0; }
  .typography ol {
    counter-reset: item; }
    .typography ol li:before {
      content: counter(item) ". ";
      counter-increment: item;
      margin-right: 3px;
      text-decoration: none; }
  .typography ul {
    list-style: none; }
  .typography ul li {
    position: relative;
    padding-left: 20px; }
    .typography ul li:before {
      content: '\2022';
      position: absolute;
      left: 0;
      top: 0;
      line-height: 0.9;
      font-size: 30px;
      color: #ff7900; }
  .typography li {
    margin: 12px 0;
    line-height: 1.6; }
    .typography li > ul,
    .typography li > ol {
      margin-bottom: 0; }
  .typography .reset-list {
    list-style: none;
    margin-left: 0; }
    .typography .reset-list li:before {
      content: ''; }
  .typography img {
    max-width: 100%;
    height: auto; }
  .typography figure > img {
    display: block; }
  .typography figure[style*="float: left"],
  .typography img[style*="float: left"],
  .typography img[style*="float:left"] {
    margin: 5px 20px 5px 0;
    max-width: 420px; }
  .typography figure.pull-left,
  .typography img.pull-left {
    margin: 5px 20px 5px 0;
    max-width: 420px; }
  .typography figure[style*="float: right"],
  .typography img[style*="float: right"],
  .typography img[style*="float:right"] {
    margin: 5px 0 5px 20px;
    max-width: 420px; }
  .typography figure.pull-right,
  .typography img.pull-right {
    margin: 5px 0 5px 20px;
    max-width: 420px; }

input {
  width: auto; }

input[type=file] {
  padding: 0;
  border: 0; }

.form table {
  width: 100%; }

.form .EditingFormTable,
.form .EditingFormTable tbody,
.form .EditingFormTable tr,
.form .EditingFormTable td {
  display: block; }

.form tr {
  margin-bottom: 25px; }

.form textarea {
  height: 180px;
  max-height: 300px; }

.form label,
.form .EditingFormLabel {
  display: inline-block;
  margin-bottom: 5px; }

.form .EditingFormLabel:not(.custom-file-label),
.form .InputLabel {
  font-family: "Open Sans", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  text-transform: uppercase; }

.form .custom-file-label {
  font-family: Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

.form .radio-list-vertical,
.form .checkbox-list-vertical,
.form .radio-list-horizontal {
  display: block; }
  .form .radio-list-vertical label,
  .form .checkbox-list-vertical label,
  .form .radio-list-horizontal label {
    margin-bottom: 0; }
  .form .radio-list-vertical .custom-control,
  .form .checkbox-list-vertical .custom-control,
  .form .radio-list-horizontal .custom-control {
    margin-bottom: 7px; }
    .form .radio-list-vertical .custom-control:last-child,
    .form .checkbox-list-vertical .custom-control:last-child,
    .form .radio-list-horizontal .custom-control:last-child {
      margin-bottom: 0; }
  .form .radio-list-vertical input,
  .form .checkbox-list-vertical input,
  .form .radio-list-horizontal input {
    display: none; }
  .form .radio-list-vertical br,
  .form .checkbox-list-vertical br,
  .form .radio-list-horizontal br {
    display: none; }

.form .radio-list-horizontal .custom-control {
  display: inline-block;
  margin-right: 30px; }

.form .ErrorLabel {
  margin-bottom: 30px; }

.form .EditingFormControlNestedControl.Error input[type=text],
.form .EditingFormControlNestedControl.Error textarea {
  border-color: #dc3545 !important;
  color: #dc3545; }

.form .form-control-error {
  line-height: 1.5; }

.form .ExplanationText {
  color: #bdbdbd;
  font-style: italic;
  margin-top: 6px; }

.form .WatermarkText {
  color: #bdbdbd; }

.form .control-group-inline {
  display: flex; }
  .form .control-group-inline input[type=text] {
    margin-right: 10px;
    max-width: 300px;
    pointer-events: none; }
  .form .control-group-inline .calendar-action {
    display: none; }

.CaptchaTable tr {
  margin: 0; }

input[type=text],
input[type=password],
input[type=email],
textarea, textarea.form-control {
  font-size: 120%;
  padding: 12px 20px;
  background: #fff;
  box-shadow: none !important; }

.form input[type=submit],
.form .btn-submit {
  position: relative;
  display: inline-block;
  border: none;
  text-decoration: none !important;
  padding: 0;
  margin-bottom: 0;
  margin-top: 0;
  line-height: 1.1;
  vertical-align: middle;
  box-shadow: none;
  border-radius: 0;
  height: auto;
  font-weight: normal;
  background-color: transparent;
  border: 2px solid;
  border-radius: 50px;
  color: #333333;
  padding: 11px 20px;
  text-transform: uppercase;
  transition: ease .3s;
  -webkit-transition: ease .3s;
  -moz-transition: ease .3s;
  -o-transition: ease .3s;
  -ms-transition: ease .3s;
  margin-top: 15px;
  padding-left: 50px;
  padding-right: 50px;
  width: auto; }
  .form input[type=submit]:hover,
  .form .btn-submit:hover {
    cursor: pointer; }
  .form input[type=submit]:active, .form input[type=submit]:focus,
  .form .btn-submit:active,
  .form .btn-submit:focus {
    outline: 0; }
  .form input[type=submit] .fa,
  .form .btn-submit .fa {
    display: inline-block;
    margin-right: 5px;
    vertical-align: top; }
  .form input[type=submit]:hover, .form input[type=submit]:focus,
  .form .btn-submit:hover,
  .form .btn-submit:focus {
    color: #ffffff;
    background-color: #4687bf;
    border-color: #4687bf; }

@media (max-width: 991.98px) {
  .form .radio-list-horizontal .custom-control {
    display: block; } }

.btn-main {
  position: relative;
  display: inline-block;
  border: none;
  text-decoration: none !important;
  padding: 0;
  margin-bottom: 0;
  margin-top: 0;
  line-height: 1.1;
  vertical-align: middle;
  box-shadow: none;
  border-radius: 0;
  height: auto;
  font-weight: normal;
  background-color: transparent;
  border: 2px solid;
  border-radius: 50px;
  color: #333333;
  padding: 11px 20px;
  text-transform: uppercase;
  transition: ease .3s;
  -webkit-transition: ease .3s;
  -moz-transition: ease .3s;
  -o-transition: ease .3s;
  -ms-transition: ease .3s; }
  .btn-main:hover {
    cursor: pointer; }
  .btn-main:active, .btn-main:focus {
    outline: 0; }
  .btn-main .fa {
    display: inline-block;
    margin-right: 5px;
    vertical-align: top; }
  .btn-main:hover, .btn-main:focus {
    color: #ffffff;
    background-color: #4687bf;
    border-color: #4687bf; }

.btn-white {
  color: #fff; }

.page-footer {
  margin-top: 80px; }

.footer {
  overflow: hidden;
  background-color: #f2f2f2;
  border-top: 1px solid #fafafa; }
  .footer p {
    margin: 0;
    text-transform: none; }

body {
  min-height: 100%; }

.page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative; }

.page-content {
  flex: 1 0 auto; }

.section {
  margin-top: 50px; }
  .section:first-child {
    margin-top: 0; }

.page-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: rgba(236, 141, 58, 0.9);
  background-blend-mode: multiply; }
  .page-image .section-header {
    min-height: 165px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .page-image .section-heading {
    text-transform: none; }
  .page-image .section-ico {
    border-width: 6px;
    margin: -20px;
    margin-left: 15px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center; }
    .page-image .section-ico svg {
      width: 100px;
      max-width: 60%;
      fill: #fff; }

.section-heading {
  font-family: "Open Sans", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 800;
  font-size: 22px;
  margin-top: 0;
  margin-bottom: 0.7em;
  padding: 12px 12px 12px 0;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd; }
  .section-heading:after {
    content: '.';
    color: #ff7900;
    font-family: 'Raleway', sans-serif; }
  .section-heading.no-border {
    font-size: 36px;
    padding: 0;
    border: 0;
    margin: 0; }

.heading {
  font-family: "Open Sans", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 800;
  font-size: 22px;
  margin-top: 0;
  margin-bottom: 0.7em;
  text-transform: uppercase; }
  .heading:after {
    content: '.';
    color: #ff7900;
    font-family: 'Raleway', sans-serif; }

.widget {
  padding-left: 0;
  padding-right: 0; }

.section-ico {
  width: 204px;
  height: 204px;
  border-radius: 50%;
  background-color: #4687bf;
  border: 5px solid #fff; }

.svg-mono svg .wdyn-ico svg,
.section-ico svg,
.nav-tabs svg,
.service-icon svg {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto; }
  .svg-mono svg .wdyn-ico svg path,
  .svg-mono svg .wdyn-ico svg circle,
  .svg-mono svg .wdyn-ico svg line,
  .svg-mono svg .wdyn-ico svg rect,
  .section-ico svg path,
  .section-ico svg circle,
  .section-ico svg line,
  .section-ico svg rect,
  .nav-tabs svg path,
  .nav-tabs svg circle,
  .nav-tabs svg line,
  .nav-tabs svg rect,
  .service-icon svg path,
  .service-icon svg circle,
  .service-icon svg line,
  .service-icon svg rect {
    stroke: inherit; }
    .svg-mono svg .wdyn-ico svg path:not([fill="none"]),
    .svg-mono svg .wdyn-ico svg circle:not([fill="none"]),
    .svg-mono svg .wdyn-ico svg line:not([fill="none"]),
    .svg-mono svg .wdyn-ico svg rect:not([fill="none"]),
    .section-ico svg path:not([fill="none"]),
    .section-ico svg circle:not([fill="none"]),
    .section-ico svg line:not([fill="none"]),
    .section-ico svg rect:not([fill="none"]),
    .nav-tabs svg path:not([fill="none"]),
    .nav-tabs svg circle:not([fill="none"]),
    .nav-tabs svg line:not([fill="none"]),
    .nav-tabs svg rect:not([fill="none"]),
    .service-icon svg path:not([fill="none"]),
    .service-icon svg circle:not([fill="none"]),
    .service-icon svg line:not([fill="none"]),
    .service-icon svg rect:not([fill="none"]) {
      fill: inherit; }

@media (max-width: 991.98px) {
  .page-image .section-header {
    min-height: 125px; }
  .page-image .section-ico {
    width: 150px;
    height: 150px;
    border-width: 5px;
    margin: -10px -10px -10px 15px;
    z-index: 99; } }

@media (max-width: 767.98px) {
  .section-heading {
    font-size: 20px; }
    .section-heading.no-border {
      font-size: 30px; }
  .page-image .section-header {
    min-height: 100px; }
  .page-image .section-ico {
    width: 120px;
    height: 120px;
    border-width: 4px;
    margin: -8px -5px -8px 10px;
    z-index: 99; } }

@media (max-width: 575.98px) {
  .section-heading {
    font-size: 18px; }
    .section-heading.no-border {
      font-size: 25px; } }

[data-ext-target]:hover {
  text-decoration: underline !important;
  cursor: pointer; }

.header {
  background-color: #f2f2f2;
  border-bottom: 1px solid #fafafa; }

.sticky-wrapper.animated .header {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16); }

.logo a {
  padding: 25px 0 20px 0; }

.animated .logo a {
  padding: 15px 0 10px 0; }

.flag-icon {
  transition: opacity .5s;
  opacity: .6; }
  .flag-icon:hover {
    opacity: 1; }

.navigation {
  text-align: right;
  display: flex; }
  .navigation li {
    display: flex;
    align-items: center; }
  .navigation li a {
    color: #999;
    white-space: nowrap;
    font-size: 20px;
    padding-top: 0;
    padding-bottom: 0;
    text-decoration: none !important; }
  .navigation li a:hover, .navigation a.current {
    color: #377ab7; }

.menu-btn {
  line-height: 1; }
  .menu-btn span {
    height: 0;
    margin: 0; }

.box-mobile {
  position: static; }

.mobile-menu {
  margin: 0;
  left: 0;
  width: 100%;
  text-align: center;
  transition: all 300ms;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.15); }
  .mobile-menu li a {
    text-decoration: none;
    font-size: 16px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-right: 0; }
    .mobile-menu li a:hover {
      color: #ff7900; }

.dot-after:after {
  content: '.';
  color: #ff7900;
  font-family: 'Raleway', sans-serif; }

.open-text {
  font-size: 22px;
  text-transform: none;
  color: #999; }

.open-title {
  font-family: "Open Sans", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 800; }
  .open-title:after {
    content: '.';
    color: #ff7900;
    font-family: 'Raleway', sans-serif; }

.blog-title {
  font-family: "Open Sans", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 800; }
  .blog-title a {
    color: inherit; }

.read-more {
  color: #4687bf;
  margin-left: 10px; }
  .read-more:hover {
    color: #4687bf;
    text-decoration: underline; }
  .read-more .fa {
    margin-left: 5px; }

.widget .widgettitle {
  font-family: "Open Sans", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 800; }
  .widget .widgettitle a {
    color: inherit;
    text-decoration: none; }

.widget ul {
  list-style-type: square; }
  .widget ul li {
    color: #ff7900; }
    .widget ul li.active {
      text-decoration: underline; }

.slider-caption {
  position: absolute;
  bottom: 23%;
  padding: 0; }

.highlight-wrapping {
  position: relative;
  left: 5px;
  padding-left: 0;
  box-shadow: 5px 0 0 #ff7900, -5px 0 0 #ff7900; }

.dark-opacity-bg {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 25px;
  color: #999999; }

.text-white {
  color: #fff; }

.w-50 {
  width: 50%; }

.para-text {
  letter-spacing: normal; }

.slider-link,
.tagcloud a {
  text-decoration: none !important; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.fade {
  opacity: 0;
  transition: opacity 0.15s linear; }
  .fade.in {
    opacity: 1; }

.h-100 {
  height: 100% !important; }

.image-gallery .gallery-list {
  margin-left: -10px;
  display: flex;
  flex-wrap: wrap; }

.image-gallery .gallery-title {
  margin-bottom: 25px; }

.image-gallery .gallery-item {
  padding: 0 0 10px 10px !important; }
  .image-gallery .gallery-item a {
    display: block;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding-bottom: 64%; }
  .image-gallery .gallery-item img {
    position: absolute;
    visibility: hidden; }

.scroll-to-top {
  position: relative;
  display: inline-block;
  border: none;
  text-decoration: none !important;
  padding: 0;
  margin-bottom: 0;
  margin-top: 0;
  line-height: 1.1;
  vertical-align: middle;
  box-shadow: none;
  border-radius: 0;
  height: auto;
  font-weight: normal;
  background-color: transparent;
  display: none;
  width: 44px;
  height: 44px;
  position: fixed;
  right: 20px;
  bottom: 0;
  z-index: 8000;
  background-color: #4687bf;
  opacity: 0.88;
  transition: ease background-color 0.3s; }
  .scroll-to-top:hover {
    cursor: pointer; }
  .scroll-to-top:active, .scroll-to-top:focus {
    outline: 0; }
  .scroll-to-top:hover {
    background-color: #366d9c; }
  .scroll-to-top .fa {
    color: #fff;
    font-size: 24px; }

.home-slider .slider-small,
.home-slider .slider-big,
.home-slider .slider-link {
  float: left;
  clear: both; }

.home-slider .slider-big {
  font-family: "Open Sans", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

.home-slider .slide {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 750px; }

.home-slider .slider-small {
  background-color: #ff7900; }

.home-slider .slider-link {
  border-color: rgba(255, 255, 255, 0.7); }
  .home-slider .slider-link i {
    color: rgba(255, 255, 255, 0.7); }
  .home-slider .slider-link:hover {
    background-color: #fff;
    border-color: #fff;
    color: #4687bf; }
    .home-slider .slider-link:hover i {
      color: #4687bf; }

.home-news-list .slide > a {
  display: block;
  padding-bottom: 55%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat; }
  .home-news-list .slide > a img {
    position: absolute;
    visibility: hidden; }

.home-para .para-main {
  padding: 98px 0; }

.home-para .para-title {
  font-family: "Open Sans", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 48px; }

.home-para .para-text {
  font-size: 18px;
  background-color: #ff7900;
  line-height: 1.4; }

.home-para .para-img {
  background-position-x: center !important; }

#team .team-box-link {
  text-decoration: none; }

#team .team-box {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding-bottom: 62.5%; }

#team .section-main .team-box {
  padding-bottom: 71%; }

#team .section-sidebar .team-box-link:last-child {
  justify-content: flex-end; }

#team img {
  width: 100%;
  display: none; }

#team .team-bottom-text {
  padding-left: 0;
  font-family: unset;
  font-size: 16px; }

.portfolio-body .port-item {
  visibility: hidden;
  padding: 1px; }
  .portfolio-body .port-item a {
    display: block;
    overflow: hidden;
    text-decoration: none; }
  .portfolio-body .port-item:hover .port-over-title {
    font-size: 23px; }
  .portfolio-body .port-item:hover .port-image {
    transform: scale(1.07, 1.07); }

.portfolio-body .port-image {
  position: relative;
  display: block;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding-bottom: 64%;
  transition: 1s all; }
  .portfolio-body .port-image img {
    position: absolute;
    visibility: hidden; }

.portfolio-body .port-bottom-text {
  text-transform: none;
  font-size: 16px;
  font-weight: normal;
  font-family: unset;
  height: 59px;
  padding: 0 20px;
  display: flex;
  align-items: center; }

.portfolio-body .port-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.2);
  text-align: center;
  padding: 25px;
  transition: 1s background-color; }

.portfolio-body .port-over-title {
  font-family: "Open Sans", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 800;
  line-height: 1.3;
  font-size: 20px;
  text-transform: uppercase;
  z-index: 100;
  color: rgba(255, 255, 255, 0.92);
  transition: 0.2s all; }

.portfolio-detail .portfolio-main-image {
  position: relative; }
  .portfolio-detail .portfolio-main-image:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: url("../img/port_opacity.png") repeat-x center bottom; }
  .portfolio-detail .portfolio-main-image .slide {
    height: 565px; }
    .portfolio-detail .portfolio-main-image .slide:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.1); }

.portfolio-detail .port-detail p {
  margin-bottom: 12px; }

.portfolio-detail .port-slider {
  display: none; }
  .portfolio-detail .port-slider .owl-wrapper {
    display: flex !important;
    align-items: center; }

.portfolio-detail .owl-controls .owl-prev,
.portfolio-detail .owl-controls .owl-next {
  font-size: 80px;
  position: absolute;
  top: 50%;
  margin-top: -40px;
  color: #b9b9b9;
  transform: scaleY(1.1); }
  .portfolio-detail .owl-controls .owl-prev:hover,
  .portfolio-detail .owl-controls .owl-next:hover {
    color: #4687bf; }

.portfolio-detail .owl-prev {
  left: -50px; }

.portfolio-detail .owl-next {
  right: -50px; }

@media (max-width: 991.98px) {
  .portfolio-detail .owl-controls .owl-prev,
  .portfolio-detail .owl-controls .owl-next {
    display: none; } }

.port-filter a {
  text-decoration: none !important; }

.service-item {
  margin-top: 85px; }
  .service-item:first-child {
    margin-top: 0; }
  .service-item .service-heading {
    font-family: "Open Sans", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 800;
    font-size: 30px;
    margin-bottom: 5px;
    text-transform: uppercase; }
    .service-item .service-heading:after {
      content: '.';
      color: #ff7900;
      font-family: 'Raleway', sans-serif; }
  .service-item .service-description-container,
  .service-item .service-action,
  .service-item .service-carousel {
    position: relative; }
  .service-item .service-description-container {
    background-color: rgba(236, 141, 58, 0.9);
    margin-bottom: 15px;
    z-index: 10; }
  .service-item .service-description {
    padding: 85px 20px 15px;
    font-size: 18px;
    color: #fff;
    line-height: 1.5; }
  .service-item .service-action {
    z-index: 10; }
  .service-item .service-carousel-container {
    visibility: hidden;
    z-index: 1; }
  .service-item .service-carousel .slide {
    padding-bottom: 54%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center; }
    .service-item .service-carousel .slide img {
      visibility: hidden;
      position: absolute; }
  .service-item .service-carousel .owl-wrapper {
    transition-delay: 1000 !important; }
  .service-item .service-list {
    margin-top: -60px; }
  .service-item .list-item {
    display: block;
    margin-top: 60px;
    text-decoration: none !important; }
    .service-item .list-item:hover .service-icon {
      border: 0px solid #fff; }
  .service-item .service-icon {
    width: 170px;
    height: 170px;
    border-radius: 50%;
    background-color: #5b86ad;
    border: 5px solid #fff;
    transition: ease 250ms;
    margin-bottom: 25px;
    margin: 0 auto;
    position: relative;
    justify-content: center;
    align-items: center; }
    .service-item .service-icon.service-icon--pin {
      position: relative;
      margin: auto;
      margin-top: 0;
      top: -122px;
      z-index: 100; }
    .service-item .service-icon svg {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: auto;
      height: auto;
      fill: #fff;
      stroke: #fff;
      max-width: 50%; }
  .service-item .item-heading {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase; }
  .service-item .item-description {
    font-size: 14px;
    line-height: 1.5;
    text-align: center;
    color: #696969; }

.services-navigation {
  list-style: none outside none;
  margin: 0 0 40px;
  overflow: hidden;
  padding: 5px 0;
  text-align: center;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd; }
  .services-navigation > li {
    display: inline-block;
    position: relative; }
    .services-navigation > li:first-child a:before {
      content: none; }
  .services-navigation a {
    color: #AAAAAA;
    display: inline-block;
    padding: 5px 20px;
    text-transform: uppercase; }
    .services-navigation a:before {
      color: #e74c3c;
      content: "\f0c8";
      font-family: fontawesome;
      font-size: 8px;
      height: 20px;
      line-height: 20px;
      margin-top: -10px;
      position: absolute;
      left: -2px;
      top: 50%;
      z-index: 3; }
    .services-navigation a.active, .services-navigation a:hover {
      color: #333; }
    .services-navigation a.active {
      pointer-events: none; }

.icon-text-list-small {
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
  list-style-type: none;
  padding-left: 0; }
  .icon-text-list-small li {
    display: flex;
    flex: 1 0 50%;
    padding-right: 10px;
    margin-bottom: 10px; }
    .icon-text-list-small li a {
      display: flex;
      white-space: nowrap; }
      .icon-text-list-small li a svg {
        width: 34px;
        height: 24px;
        vertical-align: middle;
        margin-right: 10px;
        max-width: none;
        fill: #4687bf;
        stroke: #4687bf; }
      .icon-text-list-small li a:hover svg {
        fill: #2f6089;
        stroke: #2f6089; }

@media (max-width: 767.98px) {
  .service-item .service-description {
    padding-top: 30px;
    padding-bottom: 30px; }
  .service-item .service-carousel .slide {
    padding-bottom: 74%; } }

@media (max-width: 991.98px) {
  .service-carousel-container {
    margin-top: -77px !important; } }

.outsourcing-page .section {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }

.outsourcing-page .nav-tabs {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  border: 0;
  margin-bottom: -36px; }
  .outsourcing-page .nav-tabs li {
    margin: 0; }
    .outsourcing-page .nav-tabs li:not(:last-child) {
      margin-right: 42px;
      position: relative; }
      .outsourcing-page .nav-tabs li:not(:last-child):after {
        content: "";
        height: 6px;
        width: 42px;
        background-color: #4687bf;
        position: absolute;
        left: 100%;
        top: 50%;
        margin-top: -3px; }
  .outsourcing-page .nav-tabs a {
    display: flex;
    position: relative;
    text-decoration: none !important;
    width: 72px;
    height: 72px;
    border-radius: 72px;
    margin: 0;
    padding: 12px;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 5px solid #4687bf !important; }
    .outsourcing-page .nav-tabs a.active, .outsourcing-page .nav-tabs a:hover {
      background-color: #4687bf; }
      .outsourcing-page .nav-tabs a.active svg, .outsourcing-page .nav-tabs a:hover svg {
        fill: #fff; }
      .outsourcing-page .nav-tabs a.active span, .outsourcing-page .nav-tabs a:hover span {
        display: block; }
    .outsourcing-page .nav-tabs a:hover {
      cursor: pointer; }
    .outsourcing-page .nav-tabs a.active {
      pointer-events: none; }
      .outsourcing-page .nav-tabs a.active span {
        display: none !important; }
  .outsourcing-page .nav-tabs svg {
    fill: #4687bf;
    height: 100%;
    width: auto; }
  .outsourcing-page .nav-tabs span {
    display: none;
    position: relative;
    font-size: 18px;
    color: #4687bf;
    position: absolute;
    left: 90%;
    bottom: 100%;
    margin-bottom: 10px;
    border: 6px solid #4687bf;
    border-radius: 25px 25px 25px 15px;
    white-space: nowrap;
    padding: 7px 15px; }
    .outsourcing-page .nav-tabs span:before {
      content: "";
      width: 23px;
      height: 23px;
      background: url("../img/tooltip_tip.png") no-repeat center center;
      position: absolute;
      left: -12px;
      bottom: -14px; }

.outsourcing-page .outsourcing-tabs-sticky-wrapper:not(.is-sticky) .nav-tabs {
  opacity: 1 !important; }
  .outsourcing-page .outsourcing-tabs-sticky-wrapper:not(.is-sticky) .nav-tabs li span {
    background-color: #fff; }

.outsourcing-page .outsourcing-tabs-sticky-wrapper.is-sticky .nav-tabs {
  position: relative;
  z-index: 100;
  opacity: 0;
  width: auto !important;
  flex-direction: column;
  animation: fadeInRightBig 1s;
  left: 15px;
  transition: all 200ms; }
  .outsourcing-page .outsourcing-tabs-sticky-wrapper.is-sticky .nav-tabs li {
    display: block;
    float: none; }
    .outsourcing-page .outsourcing-tabs-sticky-wrapper.is-sticky .nav-tabs li:not(:last-child) {
      margin-right: 0;
      margin-bottom: 25px; }
      .outsourcing-page .outsourcing-tabs-sticky-wrapper.is-sticky .nav-tabs li:not(:last-child):after {
        height: 25px;
        width: 6px;
        top: 100%;
        left: 33px;
        margin-top: 0; }
    .outsourcing-page .outsourcing-tabs-sticky-wrapper.is-sticky .nav-tabs li a {
      background-color: #ffffffbb; }
      .outsourcing-page .outsourcing-tabs-sticky-wrapper.is-sticky .nav-tabs li a:hover {
        width: auto;
        background-color: #4687bf; }
      .outsourcing-page .outsourcing-tabs-sticky-wrapper.is-sticky .nav-tabs li a.active {
        background-color: #4687bf; }
  .outsourcing-page .outsourcing-tabs-sticky-wrapper.is-sticky .nav-tabs span {
    margin: 0 20px;
    padding: 0;
    font-size: 22px;
    color: #fff;
    position: static;
    border: 0;
    border-radius: 0; }
    .outsourcing-page .outsourcing-tabs-sticky-wrapper.is-sticky .nav-tabs span:before {
      content: none !important; }

.outsourcing-page .outsourcing-tabs-sticky-wrapper {
  height: auto !important;
  position: relative;
  z-index: 100; }

.outsourcing-page .CTA-section {
  margin-top: 0;
  margin-bottom: -80px;
  padding: 0; }
  .outsourcing-page .CTA-section > .container {
    position: relative; }
  .outsourcing-page .CTA-section .section-heading {
    position: absolute;
    z-index: 10;
    left: 15px;
    top: 65px; }

.outsourcing-page .CTA {
  color: #685394; }

.outsourcing-page .CTA-col {
  padding: 0 15px;
  padding-top: 150px;
  padding-bottom: 65px;
  transition: all 200ms; }
  .outsourcing-page .CTA-col.hover {
    background-color: #685394;
    color: #fff; }

.outsourcing-page .btn-CTA {
  font-size: 24px;
  color: #685394 !important;
  background-color: #fff !important;
  border-color: #685394;
  padding: 25px 35px;
  min-width: 290px;
  text-transform: none; }

.outsourcing-page .wdyn-ico {
  margin-right: 10px;
  width: 80px;
  height: 65px; }
  .outsourcing-page .wdyn-ico svg {
    width: 100%;
    height: 100%;
    fill: #999;
    stroke: #999; }

@media (max-width: 991.98px) {
  .outsourcing-tabs-sticky-wrapper:not(.is-sticky) .nav-tabs {
    margin-bottom: 5px;
    margin-top: -5px; }
    .outsourcing-tabs-sticky-wrapper:not(.is-sticky) .nav-tabs li {
      margin: 5px !important; }
      .outsourcing-tabs-sticky-wrapper:not(.is-sticky) .nav-tabs li:after {
        content: none !important; }
  .CTA-col:last-child {
    padding-top: 70px !important;
    border-top: 1px solid #f1f1f1; } }

@media (max-width: 767.98px) {
  .outsourcing-page .nav-tabs {
    justify-content: flex-start; } }

/*
* Hide from both screenreaders and browsers: h5bp.com/u
*/
.hidden {
  display: none !important;
  visibility: hidden; }

/*
* Hide only visually, but have it available for screenreaders: h5bp.com/v
*/
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

/*
* Extends the .visuallyhidden class to allow the element to be focusable
* when navigated to via the keyboard: h5bp.com/p
*/
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

/*
* Hide visually and from screenreaders, but maintain layout
*/
.invisible {
  visibility: hidden; }

/*
* Clearfix: contain floats
*/
.clearfix:before,
.clearfix:after {
  content: " ";
  display: table; }

.clearfix:after {
  clear: both; }

/*
* Floats
 */
.pull-left {
  float: left !important; }

.pull-right {
  float: right !important; }

/*
* Text align
 */
.text-center {
  text-align: center; }

.text-left,
.TextLeft {
  text-align: left; }

.text-right,
.TextRight {
  text-align: right; }

.button {
  background: #4687bf;
  font-weight: bold;
  text-shadow: none;
  box-shadow: none;
  margin: 0;
  display: inline-block;
  color: #fff;
  border: none;
  border-radius: 0; }

.ui-helper-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  line-height: 1.3;
  text-decoration: none;
  font-size: 100%;
  list-style: none; }

.Debug {
  word-break: break-word; }
