.typography {
	@include cf;
	word-wrap: break-word;

	/**
	* Headings
	*/

	h1, h2, h3, h4, h5, h6,
	.h1, .h2, .h3, .h4, .h5, .h6 {
		@include font-heading;
		margin: 1.5em 0 0.6em;
		text-transform: uppercase;

		&:first-child { margin-top: 0; }

		@include dot-after;

		a {
			color: inherit;
			display: block;
		}
	}

	h1, .h1, .cms-bootstrap h1 { font-size: 35px; }
	h2, .h2, .cms-bootstrap h2 { font-size: 24px; }
	h3, .h3, .cms-bootstrap h3 { font-size: 20px; }
	h4, .h4, .cms-bootstrap h4 { font-size: 16px; }
	h5, .h5, .cms-bootstrap h5 { font-size: 14px; }
	h6, .h6, .cms-bootstrap h6 { font-size: 12px; }

	/**
	* Text
	*/

	p {
		margin: 25px 0;
		
		&:first-child,
		&:first-of-type { margin-top: 0; }
		&:last-child { margin-bottom: 0; }
	}


	/**
	* Lists
	*/

	ul,
	ol {
		margin: 20px 0 20px 20px;
		padding: 0;
		
		&:first-child,
		&:last-child { margin-bottom: 0; }
	}

	ol {
		counter-reset: item;

		li:before { 
			content: counter(item) ". "; 
			counter-increment: item;
			margin-right: 3px;
			text-decoration: none;
		}
	}

	ul {
		list-style: none;
	}

	ul li {
		position: relative;
		padding-left: 20px;

		&:before {
			content: '\2022';
			position: absolute;
			left: 0;
			top: 0;
			line-height: 0.9;
			font-size: 30px;
			color: $highlight;
		}
	}

	li {
		margin: 12px 0;
		line-height: 1.6;

		> ul,
		> ol {
			margin-bottom: 0;
		}
	}

	.reset-list {
		list-style: none;
		margin-left: 0;
		
		li:before { content: ''; }
	}

	/**
	* Images
	*/

	img {
		max-width: 100%;
		height: auto;
	}

	figure > img {
		display: block;
	}

	figure[style*="float: left"],
	img[style*="float: left"], 
	img[style*="float:left"] {
		margin: 5px 20px 5px 0;
		max-width: 420px;
	}

	figure.pull-left,
	img.pull-left {
		margin: 5px 20px 5px 0;
		max-width: 420px;
	} // ie8

	figure[style*="float: right"],
	img[style*="float: right"],
	img[style*="float:right"] {
		margin: 5px 0 5px 20px;
		max-width: 420px;
	}

	figure.pull-right,
	img.pull-right {
		margin: 5px 0 5px 20px;
		max-width: 420px;
	} // ie8
}
