.outsourcing-page {
	.section {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
	}

	.nav-tabs {
		display: flex;
		flex-wrap: wrap;
		list-style: none;
		margin: 0;
		padding: 0;
		border: 0;
		margin-bottom: -36px;

		li {
			margin: 0;

			&:not(:last-child) {
				margin-right: 42px;

				position: relative;
				&:after {
					content: "";
					height: 6px;
					width: 42px;
					background-color: $link-color;
					position: absolute;
					left: 100%;
					top: 50%;
					margin-top: -3px;
				}
			}
		}

		a {
			display: flex;
			position: relative;
			text-decoration: none !important;
			width: 72px;
			height: 72px;
			border-radius: 72px;
			margin: 0;
			padding: 12px;
			align-items: center;
			justify-content: center;
			background-color: #fff;
			border: 5px solid $link-color !important;

			&.active,
			&:hover {
				background-color: $link-color;

				svg {
					fill: #fff;
				}
				span {
					display: block;
				}
			}

			&:hover {
				cursor: pointer;
			}

			&.active {
				pointer-events: none;
				span {
					display: none !important;
				}
			}
		}

		svg {
			fill: $primary;
			height: 100%;
			width: auto;
		}

		span {
			display: none;
			position: relative;
			font-size: 18px;
			color: $link-color;
			position: absolute;
			left: 90%;
			bottom: 100%;
			margin-bottom: 10px;
			border: 6px solid $link-color;
			border-radius: 25px 25px 25px 15px;
			white-space: nowrap;
			padding: 7px 15px;

			&:before {
				content: "";
				width: 23px;
				height: 23px;
				background: url("../img/tooltip_tip.png") no-repeat center
					center;
				position: absolute;
				left: -12px;
				bottom: -14px;
			}
		}
	}

	.outsourcing-tabs-sticky-wrapper:not(.is-sticky) .nav-tabs {
		opacity: 1 !important;

		li {
			span {
				background-color: #fff;
			}
		}
	}

	.outsourcing-tabs-sticky-wrapper.is-sticky .nav-tabs {
		position: relative;
		z-index: 100;
		opacity: 0;
		width: auto !important;
		flex-direction: column;
		animation: fadeInRightBig 1s;
		left: 15px;
		transition: all 200ms;

		li {
			display: block;
			float: none;

			&:not(:last-child) {
				margin-right: 0;
				margin-bottom: 25px;

				&:after {
					height: 25px;
					width: 6px;
					top: 100%;
					left: 33px;
					margin-top: 0;
				}
			}

			a {
				background-color: #ffffffbb;

				&:hover {
					width: auto;
					background-color: $link-color;
				}

				&.active {
					background-color: $link-color;
				}
			}
		}

		span {
			margin: 0 20px;
			padding: 0;
			font-size: 22px;
			color: #fff;
			position: static;
			border: 0;
			border-radius: 0;

			&:before {
				content: none !important;
			}
		}
	}

	.outsourcing-tabs-sticky-wrapper {
		height: auto !important;
		position: relative;
		z-index: 100;
	}

	.CTA-section {
		margin-top: 0;
		margin-bottom: -80px;
		padding: 0;

		> .container {
			position: relative;
		}

		.section-heading {
			position: absolute;
			z-index: 10;
			left: 15px;
			top: 65px;
		}
	}

	.CTA {
		color: #685394;
	}

	.CTA-col {
		padding: 0 15px;
		padding-top: 150px;
		padding-bottom: 65px;
		transition: all 200ms;

		&.hover {
			background-color: #685394;
			color: #fff;
		}
	}

	.btn-CTA {
		font-size: 24px;
		color: #685394 !important;
		background-color: #fff !important;
		border-color: #685394;
		padding: 25px 35px;
		min-width: 290px;
		text-transform: none;
	}

	.wdyn-ico {
		margin-right: 10px;
		width: 80px;
		height: 65px;

		svg {
			width: 100%;
			height: 100%;
			fill: #999;
			stroke: #999;
		}
	}
}

@include media-breakpoint-down(md) {
	.outsourcing-tabs-sticky-wrapper:not(.is-sticky) .nav-tabs {
		margin-bottom: 5px;
		margin-top: -5px;

		li {
			margin: 5px !important;

			&:after {
				content: none !important;
			}
		}
	}

	.CTA-col:last-child {
		padding-top: 70px !important;
		border-top: 1px solid #f1f1f1;
	}
}

@include media-breakpoint-down(sm) {
	.outsourcing-page .nav-tabs {
		justify-content: flex-start;
	}
}
