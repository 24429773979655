.navigation {
	text-align: right;
	display: flex;

	li {
		display: flex;
		align-items: center;
	}

	li a {
		color: #999;
		white-space: nowrap;
		font-size: 20px;
		padding-top: 0;
		padding-bottom: 0;
		text-decoration: none !important;
	}

	li a:hover, a.current{
		color: #377ab7;
	}
}

.menu-btn {
	line-height: 1;
	
	span {
		height: 0;
		margin: 0;
	}
}

.box-mobile {
	position: static;
}

.mobile-menu {
	margin: 0;
	left: 0;
	width: 100%;
	text-align: center;
	transition: all 300ms;
	box-shadow: 0 15px 25px rgba(0, 0, 0, 0.15);

	li a {
		text-decoration: none;
		font-size: 16px;
		padding-top: 15px;
		padding-bottom: 15px;
		border-right: 0;

		&:hover { color: $highlight; }
	}
}