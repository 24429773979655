.service-item {
	margin-top: 85px;

	&:first-child { margin-top: 0; }

	.service-heading {
		@include dot-after;
		@include font-heading;
		font-size: 30px;
		margin-bottom: 5px;
		text-transform: uppercase;
	}

	.service-description-container,
	.service-action,
	.service-carousel {
		position: relative;
	}

	.service-description-container {
		background-color: rgba($secondary, 0.9);
		margin-bottom: 15px;
		z-index: 10;
	}

	.service-description {
		padding: 85px 20px 15px;
		font-size: 18px;
		color: #fff;
		line-height: 1.5;
	}

	.service-action {
		z-index: 10;
	}

	.service-carousel-container {
		visibility: hidden;
		z-index: 1;
	}

	.service-carousel {

		.slide {
			padding-bottom: 54%;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;

			img {
				visibility: hidden;
				position: absolute;
			}
		}

		.owl-wrapper {
			transition-delay: 1000!important;
		}
	}

	.service-list {
		margin-top: -60px;
		// display: flex;
		// flex-direction: row;
		// flex-wrap: wrap
	}

	.list-item {
		// flex: 1 1 auto;
		display: block;
		margin-top: 60px;
		text-decoration: none !important;
		

		&:hover {
			.service-icon {
				//background-color: lighten(#5b86ad, 8%);
				border: 0px solid #fff;
			}
		}
	}

	.service-icon {
		width: 170px;
		height: 170px;
		border-radius: 50%;
		background-color: #5b86ad;
		border: 5px solid #fff;
		transition: ease 250ms;
		margin-bottom: 25px;
		margin: 0 auto;
		position: relative;
		justify-content: center;
		align-items: center;

		&.service-icon--pin {
			position: relative;
			margin: auto;
			margin-top: 0;
			top: -122px;
			z-index: 100;
		}

		svg {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			width: auto;
			height: auto;
			fill: #fff;
			stroke: #fff;
			max-width: 50%;
		}
	}

	.item-heading {
		font-size: 16px;
		font-weight: 700;
		text-align: center;
		text-transform: uppercase;
	}

	.item-description {
		font-size: 14px;
		line-height: 1.5;
		text-align: center;
		color: $body-color;
	}
}

.services-navigation {
	list-style: none outside none;
    margin: 0 0 40px;
    overflow: hidden;
    padding: 5px 0;
    text-align: center;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;

	> li {
		display: inline-block;
    	position: relative;

		&:first-child a:before {
			content: none;
		}
	}

	a {
		color: #AAAAAA;
		display: inline-block;
		padding: 5px 20px;
		text-transform: uppercase;

		&:before {
			color: #e74c3c;
			content: "\f0c8";
			font-family: fontawesome;
			font-size: 8px;
			height: 20px;
			line-height: 20px;
			margin-top: -10px;
			position: absolute;
			left: -2px;
			top: 50%;
			z-index: 3;
		}

		&.active,
		&:hover {
			color: #333;
		}

		&.active {
			pointer-events: none;
		}
	}
}

.icon-text-list-small {
    display: flex;
    flex-wrap: wrap;
	align-content: stretch;
	list-style-type: none;
	padding-left: 0;

	li {
		display: flex;
		flex: 1 0 50%;
		padding-right: 10px;
		margin-bottom: 10px;
		
		a {
			display: flex;
			white-space: nowrap;

			svg {
				width: 34px;
				height: 24px;
				vertical-align: middle;
				margin-right: 10px;
				max-width: none;
				fill: $link-color;
				stroke: $link-color;
	
			}

			&:hover svg {
				fill: $link-hover-color;
				stroke: $link-hover-color;
			}
		}
	}
}

@include media-breakpoint-down(sm) {
	.service-item {		
		.service-description {
			padding-top: 30px;
			padding-bottom: 30px;
		}

		.service-carousel .slide {
			padding-bottom: 74%;
		}		
	}
}

@include media-breakpoint-down(md) {
	.service-carousel-container {
		margin-top: -77px!important;
	}
}