.scroll-to-top {
	@include btn;
	display: none;
	width: 44px;
	height: 44px;
	position: fixed;
	right: 20px;
	bottom: 0;
	z-index: 8000;
	background-color: $link-color;
	opacity: 0.88;
	transition: ease background-color 0.3s;

	&:hover {
		background-color: darken($link-color, 10%);
	}

	.fa {
	    color: #fff;
		font-size: 24px;
	}
}