.image-gallery {
	.gallery-list {
		margin-left: -10px;
		display: flex;
		flex-wrap: wrap;
	}
	
	.gallery-title {
		margin-bottom: 25px;
	}

	.gallery-item {
		padding: 0 0 10px 10px!important;

		a {
			display: block;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
			padding-bottom: 64%;
		}

		img {
			position: absolute;
			visibility: hidden;
		}
	}
}