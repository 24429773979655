/*
* Hide from both screenreaders and browsers: h5bp.com/u
*/

.hidden {
    display: none !important;
    visibility: hidden;
}

/*
* Hide only visually, but have it available for screenreaders: h5bp.com/v
*/

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

/*
* Extends the .visuallyhidden class to allow the element to be focusable
* when navigated to via the keyboard: h5bp.com/p
*/

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

/*
* Hide visually and from screenreaders, but maintain layout
*/

.invisible {
    visibility: hidden;
}

/*
* Clearfix: contain floats
*/
.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}
.clearfix:after {
    clear: both;
}


/*
* Floats
 */
.pull-left { float:left !important; }
.pull-right { float:right !important; }

/*
* Text align
 */

.text-center { text-align: center; }
.text-left,
.TextLeft { text-align: left; }
.text-right,
.TextRight { text-align: right; }


// Buttons

.button {
    background: $primary;
    font-weight: bold;
    text-shadow: none;
    box-shadow: none;
    margin: 0;
    display: inline-block;
    color: #fff;
    border: none;
    border-radius: 0;
}

// jquery ui element reset
.ui-helper-reset {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    line-height: 1.3;
    text-decoration: none;
    font-size: 100%;
    list-style: none;
}

// Kentico Debug

.Debug {
    word-break: break-word;
}