body {
	min-height: 100%;
}

.page-wrapper {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	position: relative;
}

.page-content {
	flex: 1 0 auto;
}

.section {
	margin-top: 50px;

	&:first-child {
		margin-top: 0;
	}
}

.page-image {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	background-color: rgba($secondary, 0.9);
	background-blend-mode: multiply;

	.section-header {
		min-height: 165px;
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.section-heading {
		text-transform: none;
	}	

	.section-ico {
		border-width: 6px;
		margin: -20px;
		margin-left: 15px;
		flex-shrink: 0;
		display: flex;
		align-items: center;
		justify-content: center;

		svg {
			width: 100px;
			max-width: 60%;
			fill: #fff;
		}
	}	
}

.section-heading {
	@include dot-after;
	@include font-heading;
	font-size: 22px;
	margin-top: 0;
	margin-bottom: 0.7em;
	padding: 12px 12px 12px 0;
	border-top: 1px solid #dddddd;
	border-bottom: 1px solid #dddddd;

	&.no-border {
		font-size: 36px;
		padding: 0;
		border: 0;
		margin: 0;
	}
}

.heading {
	@include dot-after;
	@include font-heading;
	font-size: 22px;
	margin-top: 0;
	margin-bottom: 0.7em;
	text-transform: uppercase;
}

.widget {
	padding-left: 0;
	padding-right: 0;
}

.section-ico {
	@include section-ico;
}

.svg-mono svg
.wdyn-ico svg,
.section-ico svg,
.nav-tabs svg,
.service-icon svg {
	max-width: 100%;
	max-height: 100%;
	height: auto;
	width: auto;

	path,
	circle,
	line,
	rect {
		stroke: inherit;

		&:not([fill="none"]) {
			fill: inherit;
		}
	}
}



@include media-breakpoint-down(md) {
	.page-image {
		.section-header {
			min-height: 125px;
		}

		.section-ico {
			width: 150px;
			height: 150px;
			border-width: 5px;
			margin: -10px -10px -10px 15px;
			z-index: 99;
		}
	}
}

@include media-breakpoint-down(sm) {
	.section-heading {
		font-size: 20px;

		&.no-border {
			font-size: 30px;
		}
	}

	.page-image {
		.section-header {
			min-height: 100px;
		}

		.section-ico {
			width: 120px;
			height: 120px;
			border-width: 4px;
			margin: -8px -5px -8px 10px;
			z-index: 99;
		}
	}
}

@include media-breakpoint-down(xs) {
	.section-heading {
		font-size: 18px;

		&.no-border {
			font-size: 25px;
		}
	}
}

[data-ext-target]:hover {
	text-decoration: underline !important;
	cursor: pointer;
}
