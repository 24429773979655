@mixin cf {
    &:before,
    &:after {
        content: " ";
        display: table;
    }

    &:after {
        clear: both;
    }
}

@mixin fa {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	transform: translate(0, 0);
}

@mixin btn {
	position: relative;
	display: inline-block;
	border: none;
	text-decoration: none!important;
	padding: 0;
	margin-bottom: 0;
	margin-top: 0;
	line-height: 1.1;
	vertical-align: middle;
	box-shadow: none;
	border-radius: 0;
	height: auto;
	font-weight: normal;
	background-color: transparent;

	&:hover {
		cursor: pointer;
	}

	&:active,
	&:focus {
		outline: 0;
	}
}

@mixin btn-main {
	@include btn;
    border: 2px solid;
    border-radius: 50px;
    color: #333333;
    padding: 11px 20px;
    text-transform: uppercase;
	transition: ease .3s;
	-webkit-transition: ease .3s;
	-moz-transition: ease .3s;
	-o-transition: ease .3s;
	-ms-transition: ease .3s;

	.fa {
		display: inline-block;
		margin-right: 5px;
		vertical-align: top;
	}

	&:hover,
	&:focus {
		color: #ffffff;
		background-color: #4687bf;
		border-color: #4687bf;
	}
}

@mixin avatar($size, $border-width: 2px, $bg-color: #bcbcbc) {
	background-color: $bg-color;
	width: $size;
	height: $size;
	position: relative;

	&,
	img {
		border-radius: 50%;
	}

	img {
		max-height: $size - ($border-width * 2); 
		max-width: $size - ($border-width * 2); 
		width: auto;
		height: auto;
		position: absolute;  
		top: 0;  
		bottom: 0;  
		left: 0;  
		right: 0;  
		margin: auto;
		// padding: $border-width;
	}
}

@mixin error-input {
	border-color: $danger!important;
	color: $danger;
}

@mixin dot-after {
	&:after {
		content: '.';
		color: $highlight;
		font-family: 'Raleway', sans-serif;
	}
}

@mixin section-ico {
	width: 204px;
	height: 204px;
	border-radius: 50%;
	background-color: $primary;
	border: 5px solid #fff;
}

@mixin img-white {
	filter: brightness(100);
}

@mixin font-heading {
	font-family: $font-family-heading;
	font-weight: 800;
}
