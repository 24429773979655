.home-page {
	.open-title {
		//color: $secondary;
	}
}

.home-slider {
	.slider-small,
	.slider-big,
	.slider-link {
		float: left;
		clear: both;
	}

	.slider-big {
		font-family: $font-family-heading;
	}

	.slide {
		 background-size: cover; 
		 background-repeat: no-repeat; 
		 background-position: center center;
		 height: 750px;
	}

	.slider-small {
		background-color: $highlight;
	}

	.slider-link {
		border-color: rgba(255, 255, 255, 0.7);

		i {
			color: rgba(255, 255, 255, 0.7);
		}

		&:hover {
			background-color: #fff;
			border-color: #fff;
			color: $primary;

			i { color: $primary; }
		}
	}
}

.home-news-list {
	.slide > a {
		display: block;
		padding-bottom: 55%;
		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;

		img {
			position: absolute;
			visibility: hidden;
		}
	}
}

.home-para {
	.para-main {
		padding: 98px 0;
	}

	.para-title {
		font-family: $font-family-heading;
		font-size: 48px;
	}

	.para-text {
		font-size: 18px;
		background-color: $highlight;
		line-height: 1.4;
	}

	.para-img {
		background-position-x: center!important;
		// background-position-y: center;
	}
}

#team {
	.team-box-link {
		text-decoration: none;
	}

	.team-box {
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		padding-bottom: 62.5%;
	}

	.section-main .team-box {
		padding-bottom: 71%;
	}

	.section-sidebar .team-box-link:last-child {
		justify-content: flex-end;
	}

	img {
		width: 100%;
		display: none;
	}
	// .section-main .team-box {
	// 	padding-bottom: 56.7%;
	// }

	// .section-sidebar .team-box {
	// 	padding-bottom: 50%;
	// }

	.team-bottom-text { 
		padding-left: 0;
		font-family: unset;
		font-size: 16px;
	}
}