.page-footer {
	margin-top: 80px;
}

.footer {
	overflow: hidden;
	background-color: #f2f2f2;
	border-top: 1px solid #fafafa;

	p { 
		margin: 0;
		text-transform: none;
	}
}