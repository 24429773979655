@mixin message {
	display: block;	
	color: #fff;
	margin: 10px 0;
	padding: 5px 10px;
}

@mixin message-inline {
	margin: 0;
	padding: 0;
	display: inline;
}

.info-message,
.InfoLabel {
	@include message;
	@extend .bg-info;
}

.error-message,
.ErrorLabel,
.alert-label {
	@include message;
	@extend .bg-danger;

}

.error-message-inline {
	color: $danger;
	margin-top: 5px;
	font-style: italic;
	display: block;
}

.success-message,
.SuccessLabel,
.alert-success {
	@include message;
	@extend .bg-success;
}

.EditingFormError,
.EditingFormInfo,
.ErrorLabel { margin-bottom: 10px; }

.EditingFormError,
.EditingFormErrorLabel,
.form-control-error { 
	@include message;
	@include message-inline;
	@extend .text-danger;
	word-spacing: normal;
}