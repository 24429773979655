.icon-calendar {
	@include fa;
	font-size: 18px;
	color: #333;
	
	&:before { content: "\f073"; }
}

.sr-only { display: none; }
.btn-icon {
	padding: 0;
	background-color: transparent;
	box-shadow: none!important;
}

#ui-datepicker-div {
	background: #eaeaea;

	.datetime-ui-datepicker-header {
		border: 0;
		background: unset;
	}

	select {
		height: auto;
		padding: 4px;
		display: inline-block;
		vertical-align: top;
		margin: 2px 1px;
		border: 0;
		border-radius: 0;
	}


	.datetime-ui-state-default {
		color: unset;
		border: 1px solid #cccccc;
		background: #f9f9f9;
		font-weight: normal;
	}

	.datetime-ui-state-active {
		background: $primary;
		color: #fff;
	}

	.action-buttons {
		display: flex;
		justify-content: space-between;
	}
	
	.btn {
		border-radius: 0;
		font-size: 15px;
	}

	.icon-caret-right,
	.icon-caret-left {
		@include fa;
	}

	.icon-caret-left:before{ content: "\f104"; }
	.icon-caret-right:before{ content: "\f105"; }
	

	.datetime-ui-datepicker-prev, .datetime-ui-datepicker-next {
		top: 5px;
		background: #ddd;
		border: 0;
	}

	.datetime-ui-datepicker-prev { left: 2px; }
	.datetime-ui-datepicker-next { right: 2px; }
}